export type sortValue = {
    headerName: any;
    columnName: string;
    sortable: boolean;
    featureFlag?: string;
};

export const headerProperties: sortValue[] = [
    { headerName: 'NHS number', columnName: 'nhsNumber', sortable: true },
    { headerName: 'Patient name', columnName: 'lastName', sortable: true },
    { headerName: 'Date of birth (Age)', columnName: 'dateOfBirth', sortable: true },
    { headerName: 'Gender', columnName: 'gender', sortable: true },
    { headerName: 'Postcode', columnName: 'postCode', sortable: true },
    { headerName: 'Pathway', columnName: 'referralPathway', sortable: true },
    { headerName: 'Service / activity', columnName: 'disposition', sortable: true },
    { headerName: 'Planned time', columnName: 'startDateTime', sortable: true },
    { headerName: 'Duration', columnName: 'duration', sortable: false },
    {
        headerName: 'Actual start time',
        columnName: 'arrivedDateTime',
        sortable: true,
    },
    {
        headerName: 'Actual end time / cancelled time',
        columnName: 'finishedDateTime',
        sortable: true,
    },
    { headerName: 'Staff', columnName: 'hcpId', sortable: true },
    { headerName: 'Status', columnName: 'jobStatus', sortable: true },
    { headerName: 'Flag', columnName: '', sortable: false },
    { headerName: 'S1 status', columnName: '', sortable: false, featureFlag: 's1Enabled' },
];

export const emptyStateMessage: string =
    'Cannot find any Visits / Admin tasks. Please change date or clear your filters.';
