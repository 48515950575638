import { FC } from 'react';

import { JobStatus, Patient, PatientAlert } from '@doc-abode/data-models';
import moment from 'moment';
import { genderMapping } from '../../../../../../constants/mainConst';
import { presentationNameLMF } from '../../../../../../helpers';
import { getAbortedDetails } from '../../../../../../helpers/getAbortedDetails';
import filterPatientAlerts from '../../../../../../helpers/ucr/filterPatientAlerts';
import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import { createStepWarnings } from '../../../../../../helpers/ucr/getWarnings';
import { isAdminJob } from '../../../../../../helpers/ucr/isAdminJob';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import {
    formatDisplayDate,
    formatDisplayDateTime,
} from '../../../../../modules/helpers/formatData';
import {
    getAdminDisplayName,
    getPathwayDisplayName,
    getServiceDisplayName,
} from '../../../forms/common/helpers';
import { useWarningConditions } from '../../../hooks/useWarningConditions';
import { IJobSummaryComponentProps, JobSummaryComponent, JobType } from './JobSummaryComponent';
import { IHCP } from './JobSummaryHeader';
import { JobSummaryWarning } from './JobSummaryWarning';

export const JobSummaryContainer: FC<{ job: Patient; hcpid: string }> = ({ job, hcpid }) => {
    const {
        RootStore: {
            ucrStore: { warnings: allWarnings, patientAlerts: allPatientAlerts },
            usersStore: { users },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: RootStore };

    const {
        id: jobId,

        // Header
        firstName,
        middleName,
        lastName,
        staffRequired,
        hcpId,
        jobStatus,
        buddyId,
        buddyJobStatus,

        // Patient Details
        addressLine1,
        addressLine2,
        town,
        postCode,
        dateOfBirth,
        age,
        gender,

        // Visit and Activity Details
        referrer,

        // Notes
        notes,
    } = job;

    const hcpJobMapper = (id: string | null | undefined, status: JobStatus): IHCP | null => {
        return id
            ? {
                  isMain: hcpid === id,
                  name: getHcpName(users, id, false),
                  status: Patient.getFriendlyVisitStatus(status),
                  hcpID: id,
              }
            : null;
    };
    let hcps: (IHCP | null)[] = [hcpJobMapper(hcpId, jobStatus)];
    if (staffRequired === 2) {
        hcps.push(hcpJobMapper(buddyId, buddyJobStatus!));
    }

    ////////////////////////////////////////////////////////////////////////////
    // Warnings
    ////////////////////////////////////////////////////////////////////////////

    const { unresolved } = filterPatientAlerts(allPatientAlerts, jobId);
    const jobWarnings = allWarnings[jobId];
    let generalWarnings: JobSummaryWarning[] = [];

    if (jobWarnings) {
        generalWarnings = jobWarnings
            .map(({ category, data }): JobSummaryWarning | null => {
                let content;

                switch (category) {
                    case 'PATIENT_SCHEDULING_CONFLICT':
                        content =
                            'The patient already has a visit scheduled within this time period';
                        break;
                    case 'HCP_SCHEDULING_CONFLICT':
                        content = `Scheduling conflict identified for: ${getHcpName(
                            users,
                            data?.hcpId,
                            false,
                        )}`;
                        break;
                    case 'HCP_AVAILABILITY_CONFLICT':
                        content = `Planned time of the visit is out of ${getHcpName(
                            users,
                            data?.hcpId,
                            false,
                        )} availability hours!`;
                        break;
                    case 'WARNING_GEOLOCATION_MISSING':
                        content = 'Address details could not be verified!';
                        break;
                    default:
                        content = null;
                        break;
                }

                if (!content) {
                    return null;
                }

                return {
                    intent: 'warning',
                    content,
                };
            })
            .filter((value: any): value is JobSummaryWarning => Boolean(value));
    }

    const warnings: JobSummaryWarning[] = [
        ...unresolved.map(
            (patientAlert: PatientAlert): JobSummaryWarning => {
                return {
                    intent: 'danger',
                    content: `Unresolved patient alert raised by ${getHcpName(
                        users,
                        patientAlert.createdBy,
                    )} at ${formatDisplayDateTime(patientAlert.createdAt)}: ${patientAlert.type}`,
                };
            },
        ),
        ...generalWarnings,
        ...createStepWarnings(users, job, pathways, adminTypes).map(
            (content): JobSummaryWarning => {
                return {
                    intent: 'warning',
                    content,
                };
            },
        ),
    ];

    ////////////////////////////////////////////////////////////////////////////
    // Aborted
    ////////////////////////////////////////////////////////////////////////////

    const isFirstUser = hcpid === job.hcpId;

    const aborted = getAbortedDetails(job, !isFirstUser);

    ////////////////////////////////////////////////////////////////////////////
    // Data Construction
    ////////////////////////////////////////////////////////////////////////////

    const isAdmin = isAdminJob(job);

    const {
        hasNonEnglishSpeaker: isNonEnglishSpoken,
        hasComplexCare: isCareComplex,
        hasCarRequired: isCarRequired,
    } = useWarningConditions({ job });

    let pathwayLabel = '';
    let serviceLabel = '';
    let activityLabel = '';

    if (!isAdmin) {
        const pathway = pathways.find((pathway: any) => pathway.value === job.referralPathway);

        pathwayLabel = getPathwayDisplayName(pathways, job.referralPathway);
        serviceLabel = getServiceDisplayName(pathway?.services, job.disposition);
    } else {
        activityLabel = getAdminDisplayName(adminTypes, job.activityType);
    }

    const data: IJobSummaryComponentProps = {
        type: isAdmin ? JobType.ADMIN : JobType.VISIT,

        header: {
            name: presentationNameLMF({ firstName, middleName, lastName }),
            hcps,
            currentHcp: hcpid,
        },

        warnings,

        data: {
            address: [
                [addressLine1, addressLine2, town]
                    .filter(Boolean)
                    .map((ln) => ln!.replace(/,\s*$/, ''))
                    .join(', '),
                postCode,
            ]
                .filter(Boolean)
                .join(' '),
            gender: gender ? genderMapping[gender] : genderMapping.not_provided,
            dateOfBirth: dateOfBirth && `${formatDisplayDate(dateOfBirth)} (age: ${age})`,
            ...aborted,
            pathway: pathwayLabel,
            referrer,
            service: serviceLabel,
            activity: activityLabel,
            preVisitNotes: notes,
            isNonEnglishSpoken,
            isCareComplex,
            isCarRequired,
            availableFrom: job.availableFrom
                ? moment(job.availableFrom).format('h:mm a')
                : undefined,
            availableTo: job.availableTo ? moment(job.availableTo).format('h:mm a') : undefined,
        },
    };

    return <JobSummaryComponent {...data} />;
};

export default JobSummaryContainer;
