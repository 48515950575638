import { FC, useEffect, useState } from 'react';
import { DateInput3, DateInput3Props } from '@blueprintjs/datetime2';
import { useField } from 'formik';

import { dateFormat as defaultDateFormat } from '../../../../constants/patientsConst';
import { devConsoleLog } from '../../../../helpers/devHelpers';

import { FormGroup } from '../FormGroup';

interface IProps extends DateInput3Props {
    name: string;
    label?: string;
    dateFormat?: string;
    required?: boolean;
    shouldClearValue?: boolean;
    timePrecision?: any;
    displayErrors?: string;
    isContinueBtnClicked?: boolean;
    inputClassName?: string;
}

interface IFieldProps extends IProps {
    field?: { [key: string]: any };
    meta?: { [key: string]: any };
    onChangeEvent: { (date: Date | null): void };
    inputClassName?: string;
}

export const DateInputComponent: FC<IFieldProps> = ({
    className,
    inputClassName = 'v2__form-date-input',
    label,
    required,
    field = {},
    meta = {},
    onChangeEvent,
    timePrecision,
    isContinueBtnClicked,
    shouldClearValue = false,
    displayErrors,
    ...props
}) => {
    const [isTouched, setIsTouched] = useState(false);

    const onChangeInputValue = (value: string | null | undefined) => {
        if (!isTouched) {
            setIsTouched(true);
        }
        if (value) {
            onChangeEvent(new Date(value));
        } else {
            onChangeEvent(null);
        }
    };
    // not sure which should take precedence the field.value or value prop.
    // going with props.value for now as it makes sense that directly setting the value
    // trumps the field.value from Formik (if the component is used as part of a Formik form
    let value = props.value || (field.value as any);
    // While we are swapping to blueprintjs v5 we need todo this check, or
    // we have to rewrite all the formik code with date fields as Date, with all the implications
    // for date data conversions around the code base and stored in api/db.
    if ((value as Date)?.toISOString) {
        value = (value as Date).toISOString();
    }
    // I think that timePrecision prop takes precedent now that we are using Blueprintjs 5
    let newTimePrecision = timePrecision;
    if (!newTimePrecision) {
        newTimePrecision = props.timePickerProps?.precision;
    }

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props?.name}
            required={required}
            touched={meta?.touched || isTouched || isContinueBtnClicked}
            error={meta?.error}
            // string that displays the error under the input box.
            displayErrors={displayErrors}
        >
            <DateInput3
                showTimezoneSelect={false}
                onError={(errorDate) => {
                    // temp default fallback error handling as the new blueprintjs 5
                    // seems to handle things differently and its causing issues.
                    // we just null the bad value, so that a change is triggered.
                    // otherwise we get a test message in the date input, but the value of the date
                    // remains the previous value.
                    devConsoleLog(
                        'DateInput::DateInputComponent date error rest to null. Date with error:',
                        errorDate,
                    );
                    //onChangeEvent(null);
                }}
                {...field}
                {...props}
                value={value}
                placeholder={props.placeholder || props?.dateFormat || defaultDateFormat}
                timePrecision={newTimePrecision}
                closeOnSelection={false}
                inputProps={{
                    ...props?.inputProps,
                    id: props?.name,
                    name: props?.name,
                    className: inputClassName,
                }}
                popoverProps={{
                    ...props?.popoverProps,
                    className: 'v2__form-popover',
                }}
                dayPickerProps={{
                    ...props?.dayPickerProps,
                    className: 'v2__form-day-picker',
                }}
                timePickerProps={
                    (props?.timePickerProps || timePrecision) && {
                        ...props?.timePickerProps,
                        precision: newTimePrecision,
                        className: 'v2__form-time-picker',
                    }
                }
                onChange={onChangeInputValue}
            />
        </FormGroup>
    );
};

const DateInputFormComponent: FC<IProps> = ({
    className,
    label,
    required,
    timePrecision,
    shouldClearValue,
    isContinueBtnClicked,
    ...props
}) => {
    const [field, meta, { setValue }] = useField(props.name);

    useEffect(() => {
        if (shouldClearValue && props.disabled && field.value !== undefined) {
            setValue(undefined);
        }
    }, [shouldClearValue, props.disabled, setValue, field.value]);

    const onChangeEvent = (date: Date | null): void => {
        if (!date) {
            setValue(null);
            return;
        }
        setValue(date);
    };
    return (
        <DateInputComponent
            className={className}
            label={label}
            required={required}
            field={field}
            meta={meta}
            onChangeEvent={onChangeEvent}
            timePrecision={timePrecision}
            outOfRangeMessage={'Invalid or past date, please choose a new date'}
            invalidDateMessage={'Invalid or past date, please choose a new date'}
            isContinueBtnClicked={isContinueBtnClicked}
            onError={(errorDate) => {
                devConsoleLog(
                    'DateInput::DateInputFormComponent date error rest to null. Date with error:',
                    errorDate,
                );
            }}
            {...props}
        />
    );
};

export default DateInputFormComponent;
