const now = (offsetInHours) => {
    const date = new Date();

    if (offsetInHours) {
        date.setHours(date.getHours() + offsetInHours);
    }

    return date;
};

export const formFields = [
    {
        name: 'systmOneRef',
        value: () => '',
        validation: { required: true, type: 'string' },
        visible: true,
    },
    {
        name: 'nhsNumber',
        value: () => '',
        validation: { required: false, type: 'string' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'jobType',
        value: () => ({ label: 'Home visit', value: 'homeVisit' }),
        validation: { required: true, type: 'object' },
        visible: true,
    },
    {
        name: 'disposition',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'blockSize',
        value: () => ({ label: '', value: null }),
        validation: { required: ['telephoneBlock'], type: 'object' },
        visible: ['telephoneBlock'],
    },
    {
        name: 'firstName',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'lastName',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'dateOfBirth',
        value: () => null,
        validation: { required: false, type: 'date' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'age',
        value: () => null,
        validation: { required: false, type: 'number' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'gender',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'contactNumber',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'addressLine1',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        visible: ['homeVisit'],
    },
    {
        name: 'addressLine2',
        value: () => '',
        validation: { required: false, type: 'string' },
        visible: ['homeVisit'],
    },
    {
        name: 'town',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        visible: ['homeVisit'],
    },
    {
        name: 'postCode',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        visible: ['homeVisit'],
    },
    {
        name: 'practice',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'notes',
        value: () => '',
        validation: { required: false, type: 'string' },
        visible: true,
    },
    {
        name: 'hcpType',
        value: () => [],
        validation: { required: true, type: 'array' },
        visible: true,
    },
    {
        name: 'nominatedHcps',
        value: () => [],
        validation: { required: false, type: 'array' },
        visible: true,
    },
    {
        name: 'languagesSpoken',
        value: () => [],
        validation: { required: false, type: 'array' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'speciality',
        value: () => [],
        validation: { required: false, type: 'array' },
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'flags',
        value: () => [],
        validation: { required: false, type: 'array' },
        visible: true,
    },
    {
        name: 'price',
        value: () => 80,
        validation: { required: true, type: 'number' },
        visible: true,
    },
    {
        name: 'unitPrice',
        value: () => ({}),
        validation: { required: false, type: 'object' },
        visible: ['telephoneBlock'],
    },
    {
        name: 'hidePriceInApp',
        value: () => false,
        validation: { required: false, type: 'boolean' },
        visible: true,
    },
    {
        name: 'expiryDate',
        value: () => now(1),
        validation: { required: true, type: 'date' },
        visible: true,
    },
    {
        name: 'expiryTime',
        value: () => now(1),
        validation: { required: true, type: 'date' },
        visible: true,
    },
    {
        name: 'latitude',
        value: () => null,
        validation: { required: false, type: 'coordinates' },
    },
    {
        name: 'longitude',
        value: () => null,
        validation: { required: false, type: 'coordinates' },
    },
    {
        name: 'version',
        value: () => 0,
        validation: { required: false, type: 'number' },
    },
];

export const getFieldsDefaultState = () => {
    let defaultState = {
        fields: {},
        validation: {},
        errors: {},
        visible: {},
    };

    for (let i = 0; i < formFields.length; i++) {
        let name = formFields[i].name;

        defaultState.fields[name] = formFields[i].value();
        defaultState.validation[name] = formFields[i].validation;
        defaultState.errors[name] = [];
        defaultState.visible[name] = formFields[i].visible;
    }

    return defaultState;
};
