import { Patient, S1JobSyncStatus } from '@doc-abode/data-models';
import { getJobS1SyncStatus, shouldBeSyncedToS1 } from '@doc-abode/helpers';

import { ViewToShow } from '../../../../constants/mainConst';
import { isMultiAssigneeJob } from '../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';

export const useJobS1SyncStatusViewModel = (job: Patient) => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled, pathways, adminTypes },
            s1Store: { linkedVisits, linkedVisitsLoading },
        },
    } = useStores() as { RootStore: RootStore };

    const isS1SyncEnabled = isFeatureEnabled('s1Enabled');

    // Setting defaults based on the assumption that we do not have S1 sync enabled
    let showS1SyncStatus = false;
    let s1SyncStatusHcp1 = S1JobSyncStatus.DO_NOT_SYNC;
    let s1SyncStatusHcp2 = S1JobSyncStatus.DO_NOT_SYNC;

    // Even with S1 sync enabled, a job may not need to be synced based on its pathway > service or admin > activity type
    if (isS1SyncEnabled) {
        showS1SyncStatus = shouldBeSyncedToS1(job, pathways, adminTypes);
    }

    // If a job should be synced, we must determine it's status
    if (showS1SyncStatus) {
        s1SyncStatusHcp1 = getJobS1SyncStatus(job, linkedVisitsLoading, linkedVisits, false);

        // If the job is a dbl-up return the status of the 2nd HCP
        if (isMultiAssigneeJob(job)) {
            s1SyncStatusHcp2 = getJobS1SyncStatus(job, linkedVisitsLoading, linkedVisits, true);
        }
    }

    const getS1SyncStatusDisplayLabel = (isBuddy: boolean) => {
        return isBuddy ? s1SyncStatusHcp2 : s1SyncStatusHcp1;
    };

    const getS1SyncStatusClassname = (view: ViewToShow, isBuddy: boolean) => {
        let label = 'na';
        const status = getS1SyncStatusDisplayLabel(isBuddy);
        if (status === S1JobSyncStatus.LOADING) {
            label = 'loading';
        }
        if (status === S1JobSyncStatus.NOT_SYNCED) {
            label = 'not_synced';
        }
        if (status === S1JobSyncStatus.SYNCED) {
            label = 'synced';
        }

        if (view === ViewToShow.TIMELINE) {
            return `ucr__calendar-s1-tag ucr__calendar-s1-tag--${label}`;
        }

        if (view === ViewToShow.VISITS_LIST) {
            return `ucr-listview__s1-status ucr-listview__s1-status--${label}`;
        }

        return '';
    };

    return {
        isS1SyncEnabled,
        isLoading: linkedVisitsLoading,
        showS1SyncStatus,
        s1SyncStatusHcp1,
        s1SyncStatusHcp2,
        getS1SyncStatusDisplayLabel,
        getS1SyncStatusClassname,
    };
};
