import { TimePrecision } from '@blueprintjs/datetime2';
import { FC } from 'react';
import { observer } from 'mobx-react';
import { FormikValues } from 'formik';

import { getMomentDateFormatter } from '../../../../../helpers/ucr/getMomentDateFormatter';
import { Button, ButtonSizes } from '../../../../v2/components';
import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { TextInput, DateInput, Select } from '../../../../v2/form';
import { FormMode, FormSteps } from '../common';
import { useReferralDetailsViewModel } from './useReferralDetailsViewModel';
import { S1ReferralSelect } from './S1ReferralSelect';

const MAXDATE = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const MINDATE = new Date(1900, 1, 1);

type ReferralDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any) => void;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};

const ReferralDetails: FC<ReferralDetailsType> = ({
    loading,
    formMode,
    values,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        activeReferrals,
        onSyncS1Referrals,
        referrer,
        buttonName,
        onSubmit,
        referralSelectHelperText,
        s1Enabled,
    } = useReferralDetailsViewModel({
        formMode,
        values,
        onNextStep,
        onSaveForm,
    });

    return (
        <div className="v2__form-block">
            {s1Enabled ? (
                <S1ReferralSelect
                    loading={loading}
                    referrals={activeReferrals}
                    onSyncS1Referrals={onSyncS1Referrals}
                    referralSelectHelperText={referralSelectHelperText}
                />
            ) : (
                <TextInput
                    name="systmOneRef"
                    className="v2__form-group--pos-1-2"
                    label="Referral reference number"
                    disabled={loading}
                />
            )}
            <Select
                name="referrer"
                className="v2__form-group--pos-1-2"
                label="Referrer"
                disabled={loading}
            >
                <option value="">Not set</option>
                {referrer
                    ?.slice()
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((option: string) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
            </Select>
            {!s1Enabled && (
                <DateInput
                    showTimezoneSelect={false}
                    name="referralDateTime"
                    className="v2__form-group--pos-1-2"
                    label="Referral date"
                    dateFormat={dateTimeFormat}
                    {...getMomentDateFormatter(dateTimeFormat)}
                    dateFnsFormat={dateTimeFormat}
                    disabled={loading}
                    timePickerProps={{
                        showArrowButtons: true,
                        precision: TimePrecision.MINUTE,
                    }}
                    maxDate={MAXDATE}
                    minDate={MINDATE}
                />
            )}
            <label className="v2__form-group">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSubmit}
                />
            </label>
        </div>
    );
};

export default observer(ReferralDetails);
