import { JobStatus, Patient } from '@doc-abode/data-models';
import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { FormikValues } from 'formik';
import { Button, ButtonSizes } from '../../../../v2/components';
import { dateFormat } from '../../../../../constants/patientsConst';
import {
    DateInput,
    Select,
    TimeInput,
    ISuggestOption,
    TextArea,
    Checkbox,
    DurationInput,
} from '../../../../v2/form';
import useStores from '../../../../../hook/useStores';
import { isDisableFormBtn } from '../../../../../helpers';
import { getDateEndTime, getAllWarnings, getMomentDateFormatter } from '../../../../../helpers/ucr';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { FormMode, FormSteps, getButtonName, warningHighlight } from '../common';
import Warnings from '../../../../v2/form/Warnings';
import { compareByLabels } from '../../../../modules/helpers/sortFunctions';
import RootStore from '../../../../../stores/RootStore';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import { IHcp } from '../../../../../interfaces/ucr';
import { HcpSelect } from '../../components';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { useHcpSuggestionOptions } from '../../../../../hook/useHcpSuggestionOptions';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';

type ActivityDetailsType = {
    values: FormikValues;
    loading: boolean;
    onNextStep: (next: FormSteps, prev: FormSteps) => void;
    setFieldValue: (field: string, value: any) => void;
    formMode: FormMode;
    onSaveForm?: (values: FormikValues) => Promise<void>;
};

const requiredFields: Array<keyof FormikValues> = [
    'activityType',
    'hcpId',
    'visitDate',
    'startTime',
    'duration',
];

const ActivityDetails: FC<ActivityDetailsType> = ({
    values,
    loading,
    onNextStep,
    setFieldValue,
    onSaveForm = (values: FormikValues) => {},
    formMode,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { jobs, hourStart, warnings, focusedJobId, openAlert, closeAlert },
            schedulesStore: { getSchedulesByDate },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: RootStore };
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);

    const [startTimeState, setStartTimeState] = useState(values.startTime);
    const [showUnavailableHcp, setShowUnavailableHcp] = useState(false);

    const staffStyles: {
        option?: any;
    } = {};

    useEffect(() => {
        getSchedulesByDate(moment(values.visitDate).format('YYYY-MM-DD'));
    }, [values.visitDate, getSchedulesByDate]);

    useEffect(() => {
        const endTime = values.startTime
            ? getDateEndTime(values.startTime, values.duration)
            : moment().startOf('day').toDate();

        setFieldValue('endTime', endTime);
    }, [values.startTime, values.duration, setFieldValue]);

    // we need to update the startTime with the date field date but copy the hour and minutes.

    useEffect(() => {
        if (!moment(values.startTime).isSame(startTimeState)) {
            setStartTimeState(values.startTime);
        }
    }, [values.startTime, startTimeState]);

    useEffect(() => {
        if (values.visitDate && !moment(values.startTime).isSame(startTimeState)) {
            const startTimeDate = moment(values.startTime);
            const date = moment(values.visitDate)
                .set('hours', startTimeDate.hour())
                .set('minutes', startTimeDate.minute())
                .set('seconds', 0)
                .set('milliseconds', 0);

            setFieldValue('startTime', date.toDate());
        }
    }, [values.visitDate, values.startTime, setFieldValue, setStartTimeState, startTimeState]);

    const schedulingWarnings = useVisitWarnings(values as VisitValuesType);

    const hcpSuggestionOptions = useHcpSuggestionOptions(values as VisitValuesType);

    const hcpList: ISuggestOption[] = useMemo(() => {
        return [
            { label: 'Not set', value: '' },
            ...hcpSuggestionOptions
                .filter((l: ISuggestOption) => {
                    return showUnavailableHcp || values.hcpId === l.value ? l : l.isWorking;
                })
                .sort(compareByLabels)
                .sort((a: { value: string }, b: { value: string }) => {
                    return a.value === values.previouslyHcpId ||
                        a.value === values.previouslyBuddyId
                        ? -1
                        : b.value === values.previouslyHcpId || b.value === values.previouslyBuddyId
                        ? 1
                        : 0;
                }),
        ];
    }, [
        hcpSuggestionOptions,
        showUnavailableHcp,
        values.hcpId,
        values.previouslyHcpId,
        values.previouslyBuddyId,
    ]);

    if (formMode === FormMode.FOLLOW_UP) {
        if (values.hcpIdTemp) {
            const user: IHcp = users.find((user: IHcp) => user.userId === values?.hcpIdTemp);
            hcpList.splice(
                hcpList.findIndex(({ value }) => user.userId === value),
                1,
            );

            hcpList.splice(1, 0, {
                label: user.userName,
                value: user.userId,
            });
        }
        staffStyles.option = (provided: any, state: any) => ({
            ...provided,
            fontWeight: state.data.value === values?.hcpIdTemp ? 'bold' : 'normal',
        });
    }

    const isErrors = isDisableFormBtn(values, requiredFields);
    const buttonName = getButtonName(formMode, FormSteps.ACTIVITY);
    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});
    const [visitStepWarnings, setVisitStepWarnings] = useState<any>({});
    const onSubmit = () => {
        if (!isErrors) {
            onNextStep(FormSteps.REVIEW, FormSteps.ACTIVITY);
        } else {
            setIsContinueBtnClicked(true);
        }
    };
    const MAXDATE = moment().add(1, 'year').toDate();
    const MINTIME = moment().startOf('day').hour(hourStart).toDate();
    const defaultStartTime = moment().startOf('minutes').toDate();

    useEffect(() => {
        const allStepWarnings = getAllWarnings(values, pathways, adminTypes);
        setVisitStepWarnings(allStepWarnings);
    }, [adminTypes, pathways, values]);

    useEffect(() => {
        const warningArr: any = [];
        Object.keys(schedulingWarnings || {}).forEach((k) => {
            warningArr.push(schedulingWarnings[k]);
        });

        const newWarnings = warningHighlight({
            warnings: warningArr,
            stepWarnings: visitStepWarnings,
            values: values,
        });
        setHighlightedWarnings(newWarnings);
    }, [warnings, values, visitStepWarnings, schedulingWarnings]);

    useEffect(() => {
        if (values.hcpId && !hcpList.find((hcp) => hcp.value === values.hcpId)?.isWorking) {
            setShowUnavailableHcp(true);
        }
    }, [hcpList, values.hcpId]);

    const currentJob = jobs.find((job: Patient) => job.id === focusedJobId);

    const onSaveClick = () => {
        if (currentJob?.jobStatus === JobStatus.CURRENT && currentJob?.hcpId !== values.hcpId) {
            openAlert({
                message: 'The visit status is current. Are you sure you want to change it?',
                isOpen: true,
                onConfirm: () => {
                    onSubmit();
                    closeAlert();
                },
            });
            return;
        }
        onSubmit();
    };

    const options = Object.values(adminTypes)
        .filter((adminType: any) => {
            return adminType.enabled;
        })
        .sort((a: any, b: any) => {
            return a.label.localeCompare(b.label);
        })
        .map((adminType: any) => {
            return <option value={adminType.value}>{adminType.label}</option>;
        });

    const finalOptions = [<option value="">Select...</option>, ...options];

    const jobStatus: JobStatus = values.jobStatus;
    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    return (
        <div className="v2__form-block">
            <Select
                name="activityType"
                className="v2__form-group--pos-1-2"
                label="Activity"
                required
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            >
                {finalOptions}
            </Select>
            <DateInput
                name="visitDate"
                className="v2__form-group--pos-1-2"
                label="Planned date of job"
                {...getMomentDateFormatter(dateFormat)}
                dateFormat={dateFormat}
                required
                disabled={loading}
                displayErrors={highlightedWarnings['visitDate']}
                maxDate={MAXDATE}
                minDate={MINTIME}
                isContinueBtnClicked={isContinueBtnClicked}
            />
            <TimeInput
                name="startTime"
                className="v2__form-group--pos-1-3"
                label="Planned start time"
                defaultValue={values.startTime || defaultStartTime}
                selectAllOnFocus
                disabled={loading}
                required
                displayErrors={highlightedWarnings['startTime']}
            />
            <DurationInput
                name="duration"
                className="v2__form-group--pos-2-3"
                label="Planned duration"
                selectAllOnFocus
                disabled={loading}
                required
            />
            <TimeInput
                name="endTime"
                className="v2__form-group--pos-1-2"
                label="Expected end time"
                disabled
            />
            <TextArea
                name="notes"
                className="v2__form-group--pos-1-1"
                label="Pre-job notes"
                disabled={loading}
            />
            <Checkbox
                name="Show unavailable"
                className="v2__form-group--pos-1-2"
                label="Staff member"
                title="Show unavailable"
                disabled={loading}
                checked={showUnavailableHcp}
                onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                aria-label="show unavailable"
            />
            <HcpSelect
                patient={values as VisitValuesType}
                disabled={loading}
                includeUnavailableHcps={showUnavailableHcp}
                ariaLabel="staff select"
            />
            <ConditionalDisplay show={showPatientNotes}>
                <TextArea
                    name="postVisitNotes"
                    className="v2__form-group--pos-1-1"
                    label="Post-job notes"
                    disabled={loading}
                />
            </ConditionalDisplay>
            <div className="v2__form-group--pos-1-1">
                {(schedulingWarnings || (visitStepWarnings && visitStepWarnings.length > 0)) && (
                    <Warnings warnings={schedulingWarnings} stepWarnings={visitStepWarnings} />
                )}
            </div>
            <label className="v2__form-group">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSaveClick}
                    type="submit"
                />
            </label>
        </div>
    );
};

export default observer(ActivityDetails);
