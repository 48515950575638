const genderOptions = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Non-binary', value: 'non_binary' },
    { label: 'Transgender', value: 'transgender' },
    { label: 'Intersex', value: 'intersex' },
    { label: 'Other non-listed', value: 'non_listed' },
    { label: 'Not provided', value: 'not_provided' },
].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

export const fetchLovs = () => ({
    languagesSpoken: [
        { label: 'Arabic', value: 'Arabic' },
        { label: 'Bengali', value: 'Bengali' },
        { label: 'Cantonese', value: 'Cantonese' },
        { label: 'French', value: 'French' },
        { label: 'German', value: 'German' },
        { label: 'Gujarati', value: 'Gujarati' },
        { label: 'Italian', value: 'Italian' },
        { label: 'Lithuanian', value: 'Lithuanian' },
        { label: 'Mandarin', value: 'Mandarin' },
        { label: 'Other Chinese', value: 'Other Chinese' },
        { label: 'Polish', value: 'Polish' },
        { label: 'Portuguese', value: 'Portuguese' },
        { label: 'Punjabi', value: 'Punjabi' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Spanish', value: 'Spanish' },
        { label: 'Tamil', value: 'Tamil' },
        { label: 'Turkish', value: 'Turkish' },
        { label: 'Urdu', value: 'Urdu' },
        { label: 'Welsh', value: 'Welsh' },
    ],
    speciality: [
        { label: 'Cardiology', value: 'Cardiology' },
        { label: 'Dermatology', value: 'Dermatology' },
        { label: 'Diabetes', value: 'Diabetes' },
        { label: 'Elderly', value: 'Elderly' },
        { label: 'ENT', value: 'ENT' },
        { label: 'Gastroenterology', value: 'Gastroenterology' },
        { label: 'Mental health', value: 'Mental health' },
        { label: 'MSK', value: 'MSK' },
        { label: 'Neurology', value: 'Neurology' },
        { label: 'Ophthalmology', value: 'Ophthalmology' },
        { label: 'Paediatrics', value: 'Paediatrics' },
        { label: 'Palliative', value: 'Palliative' },
        { label: 'Respiratory', value: 'Respiratory' },
        {
            label: 'Section 12(2) approved doctor (MHA)',
            value: 'Section 12(2) approved doctor (MHA)',
        },
        { label: 'Urology', value: 'Urology' },
        { label: "Woman's health/O&G", value: "Woman's health/O&G" },
    ],
    flags: [
        { label: 'Care home', value: 'Care home' },
        { label: 'COVID-19', value: 'COVID-19' },
        { label: 'Test', value: 'Test' },
    ],
    gender: [{ label: 'Not Set', value: '', isDisabled: true }, ...genderOptions],
    genderStaffPreferred: [{ label: 'No preference', value: 'nopreference' }, ...genderOptions],
    jobType: [
        { label: 'Home visit', value: 'homeVisit' },
        { label: 'Telephone', value: 'telephone' },
        { label: 'Telephone block', value: 'telephoneBlock' },
    ],
    controllerReverseReason: [
        {
            label: 'Healthcare provider requested reinstantiation of visit',
            value: 'Healthcare provider requested reinstantiation of visit',
        },
        {
            label: 'Patient located',
            value: 'Patient located',
        },
        {
            label: 'Patient requested reinstantiation of visit',
            value: 'Patient requested reinstantiation of visit',
        },
        {
            label: 'Capacity constraints resolved',
            value: 'Capacity constraints resolved',
        },
        {
            label: 'Correct location information received',
            value: 'Correct location information received',
        },
        {
            label: 'Cancelled in error',
            value: 'Cancelled in error',
        },
        {
            label: 'Other',
            value: 'Other',
        },
    ],
    controllerPatientAlertResolutionReason: [
        {
            label: 'Select a resolution',
            value: false,
            disabled: true,
        },
        { label: 'Cancelled - Alert raised in error', value: 'Cancelled - Alert raised in error' },
        {
            label: 'Resolved - Patient care handed to another healthcare professional or service',
            value: 'Resolved - Patient care handed to another healthcare professional or service',
        },
        {
            label: 'Resolved - Patient alert dealt with',
            value: 'Resolved - Patient alert dealt with',
        },
        {
            label: 'Resolved - Access to patient gained',
            value: 'Resolved - Access to patient gained',
        },
        {
            label: 'Resolved - Whereabouts of patient established',
            value: 'Resolved - Whereabouts of patient established',
        },
        {
            label: 'Resolved - Alternative transport arranged',
            value: 'Resolved - Alternative transport arranged',
        },
        { label: 'Other', value: 'Other' },
    ],
    controllerStaffAlertResolutionReason: [
        {
            label: 'Select a resolution',
            value: false,
            disabled: true,
        },
        {
            label: 'Staff personally confirmed their safety',
            value: 'Staff personally confirmed their safety',
        },
        {
            label: 'Staff confirmed as safe by other parties',
            value: 'Staff confirmed as safe by other parties',
        },
        {
            label: 'Staff alert dealt with by emergency services',
            value: 'Staff alert dealt with by emergency services',
        },
        {
            label: 'Staff alert raised in error',
            value: 'Staff alert raised in error',
        },
        {
            label: 'Staff alert otherwise dealt with',
            value: 'Staff alert otherwise dealt with',
        },
    ],
});
