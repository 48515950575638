import { FC } from 'react';
import { FormikValues } from 'formik';
import moment from 'moment';
import { dateFormat } from '../../../../../constants/patientsConst';
import { genderMapping } from '../../../../../constants/mainConst';
import { presentationNameLMF } from '../../../../../helpers/names';
import { Text } from '../../../../v2/form';

export type PatientDetailsReviewFormType = {
    values: FormikValues;
};
const PatientDetailsReviewForm: FC<PatientDetailsReviewFormType> = ({ values }) => {
    const allContactNumbers = values.contactNumber
        ? [values.contactNumber, ...(values.additionalContactNumbers || [])]
        : [];

    let genderPreferredMapping = values.staffPreferredGender ? values.staffPreferredGender : [];
    genderPreferredMapping = genderPreferredMapping.map((index: any) => {
        if (index) {
            return genderMapping[index];
        }
        return '';
    });

    return (
        <div className="v2__form-section">
            <Text name="nhsNumber" title="NHS number" description={values.nhsNumber} />
            <Text name="name" title="Name" description={presentationNameLMF(values)} />
            <Text
                name="contactNumber"
                title="Phone number(s)"
                description={
                    allContactNumbers?.map((number: string) => number && `+${number}`) || 'Not set'
                }
            />
            <Text
                name="dateOfBirth"
                title="Date of birth"
                description={
                    values.dateOfBirth ? moment(values.dateOfBirth).format(dateFormat) : 'Not set'
                }
            />
            <Text name="Gender" title="Gender" description={genderMapping[values.gender]} />
            <Text name="addressLine1" title="Address line 1" description={values.addressLine1} />
            <Text name="addressLine2" title="Address line 2" description={values.addressLine2} />
            <Text name="addressLine3" title="Address line 3" description={values.addressLine3} />
            <Text name="town" title="Town" description={values.town} />
            <Text name="postCode" title="Post code" description={values.postCode} />
            <Text
                name="languagesSpoken"
                title="Spoken language"
                description={values.languagesSpoken}
            />
            <Text
                name="staffPreferredGender"
                title="Gender of visiting staff"
                description={genderPreferredMapping}
            />
        </div>
    );
};

export default PatientDetailsReviewForm;
