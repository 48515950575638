import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { ViewToShow } from '../../../../constants/mainConst';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';

const viewPathParts: { [key in ViewToShow]: string } = {
    [ViewToShow.TIMELINE]: 'timeline',
    [ViewToShow.VISITS_LIST]: 'listview',
    [ViewToShow.MAP]: 'mapview',
    [ViewToShow.PATIENTS_LIST]: 'patients',
    [ViewToShow.S1_MESSAGES]: 'systmone-messages',
};

const getViewPart = (viewToShow: ViewToShow): string | undefined => {
    return viewPathParts[viewToShow];
};

export function useView() {
    const {
        RootStore: {
            ucrStore: { viewToShow, setBottomPanelOpen },
        },
    } = useStores<{ RootStore: RootStore }>();

    // If this is failing in tests, mock 'react-router' with jest
    const { push } = useHistory();

    const clearDeepLink = useCallback(() => {
        const part = getViewPart(viewToShow);
        if (!part) {
            return;
        }

        push(`/scheduling/${part}`);
        setBottomPanelOpen(false);
    }, [push, setBottomPanelOpen, viewToShow]);

    const openDeepLink = useCallback(
        (visitId: string, userToFocus?: string) => {
            const part = getViewPart(viewToShow);
            if (!part) {
                return;
            }

            push(`/scheduling/${part}/${visitId}/${userToFocus}`);
            setBottomPanelOpen(true);
        },
        [push, setBottomPanelOpen, viewToShow],
    );

    const currentViewState = useMemo(
        () => ({
            visitList: viewToShow === ViewToShow.VISITS_LIST,
            timeline: viewToShow === ViewToShow.TIMELINE,
            map: viewToShow === ViewToShow.MAP,
            patientList: viewToShow === ViewToShow.PATIENTS_LIST,
            s1Messages: viewToShow === ViewToShow.S1_MESSAGES,
        }),
        [viewToShow],
    );

    const navigateTo = useCallback(
        (target: ViewToShow) => {
            // Don't navigate to current page
            const isCurrent = viewToShow === target;
            if (isCurrent) {
                return;
            }

            const part = getViewPart(target);
            if (!part) {
                return;
            }

            push(`/scheduling/${part}`);
            setBottomPanelOpen(false);
        },
        [push, setBottomPanelOpen, viewToShow],
    );

    return useMemo(
        () => ({
            clearDeepLink,
            openDeepLink,
            navigateTo,
            currentViewState,
        }),
        [clearDeepLink, currentViewState, navigateTo, openDeepLink],
    );
}
