import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS, reaction, autorun } from 'mobx';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import {
    FormGroup,
    InputGroup,
    NumericInput,
    TextArea,
    Button,
    RadioGroup,
    Radio,
    Checkbox,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import ReactPhoneInput from 'react-phone-input-2';
import Select from 'react-select';

import Modal from '../../modal/Modal';
import Error from '../../forms/Error';
import Loader from '../../helpers/Loader';
import { getFieldsDefaultState } from './formFields';
import { emptyErrorByType, getAPIReadyJobData, regexFieldValidation } from './formFormats';
import {
    parseDateStringToJSDate,
    formatDisplayDate,
    formatPenceToPounds,
    getDispositionExpiryDateTime,
    getAgeFromDate,
    formatPractices,
    splitPracticeDetails,
} from '../../helpers/formatData';
import { getCoordinates } from '../../../../api/gmapsApi';
import { isDateTimeInPast } from './validateFields';
import AppToaster from '../../../modules/helpers/Toaster';
import { PracticeSelect } from '../../../common/PracticeSelect';
import { DateInput3 } from '@blueprintjs/datetime2';
import { AbortJobDialog } from '../../../pages/common/forms/abortAction/AbortJobDialog';

const MULTI_SELECT_FIELDS = ['languagesSpoken', 'speciality', 'flags', 'hcpType', 'nominatedHcps'];
const JobForm = inject('RootStore')(
    observer(
        class JobForm extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    fields: getFieldsDefaultState().fields,
                    validation: getFieldsDefaultState().validation,
                    errors: getFieldsDefaultState().errors,
                    visible: getFieldsDefaultState().visible,
                    helpers: {
                        minDate: new Date('1900-01-01'),
                        maxDate: new Date('2030-01-01'),
                        apiDateFormat: 'YYYY-MM-DDTHH:mm:ss',
                    },
                    formValid: null,
                    isLoading: false,
                    isAbortDialogShown: false,
                    practiceList: 'GP_PRACTICES',
                    isShowAlert: false,
                    priceHasChanged: false,
                };
            }

            componentDidMount() {
                // Populate the job details if it's an edit form
                if (this.props.actionType === 'edit') {
                    const { id } = this.props.match.params;
                    const job = this.props.RootStore.jobsStore.getJob(id);

                    const { nominatedHcps } = this.props.RootStore.lovsStore;

                    if (nominatedHcps.length > 0) {
                        this.handlePopulate(job);
                    } else {
                        reaction(
                            () => nominatedHcps.length,
                            () => this.handlePopulate(job),
                        );
                    }
                }

                this.defaultShiftPriceDisposer = autorun(() => {
                    if (
                        this.props.RootStore.configStore.defaultShiftPrice &&
                        !this.state.priceHasChanged
                    ) {
                        this._handlePriceChange(this.props.RootStore.configStore.defaultShiftPrice);
                    }
                });
            }

            // TEXT / TEXTAREA / DATE / TIME / SELECT CHANGE
            handleInputChange = (value, field, callback = () => {}) => {
                // If field is a multi-select, maintain it as an empty array rather than being null
                if (MULTI_SELECT_FIELDS.includes(field) && value === null) {
                    value = [];
                }

                // React state doesn't handle updating multi-level state. So below is an accepted technique, to do that
                let fields = { ...this.state.fields };

                // Update the Age if Date of Birth has been changed
                if (field === 'dateOfBirth') {
                    fields['age'] = getAgeFromDate(value);
                }

                // if the age is updated, we should clear the date of birth field
                if (field === 'age') {
                    fields['dateOfBirth'] = null;
                }

                // Update expiry date and times if the disposition has been changed
                if (field === 'disposition') {
                    const dispositionExpiryDateTime = getDispositionExpiryDateTime(
                        value.timeInMins,
                    );
                    fields['expiryDate'] = dispositionExpiryDateTime;
                    fields['expiryTime'] = dispositionExpiryDateTime;
                }

                fields[field] = value;
                this.setState({ fields }, callback);

                // Prevent showing errors until the user has tried to submit
                // to avoid all fields showing red the first time they type something
                // But then start tracking their changes in order to remove errors as they fix them
                if (this.state.formValid !== null) {
                    setTimeout(() => {
                        this.validateFields();
                    });
                }
            };

            validateFields = () => {
                let errorsList = {},
                    errorsCount = 0;
                let isShowAlert = false;

                // Add an error message for a given field
                const setError = (field, errorMessage, errorsList) => {
                    errorsList[field].push(errorMessage);
                    return errorsList;
                };

                // For each field, if it is REQUIRED, generate an error message
                _.forIn(this.state.errors, (value, field) => {
                    errorsList[field] = [];

                    const fieldValue = this.state.fields[field];

                    const {
                        required,
                        type,
                        regex: { rule: regex, validationMessage: regexErrorMessage } = {},
                    } = this.state.validation[field];

                    let errorMessage;

                    if (regex && !regexFieldValidation(fieldValue, regex)) {
                        errorMessage = regexErrorMessage;
                    } else if (
                        required === true ||
                        (Array.isArray(required) &&
                            required.includes(this.state.fields.jobType.value))
                    ) {
                        errorMessage = emptyErrorByType(fieldValue, type);
                    }

                    if (errorMessage && errorMessage !== '') {
                        errorsList = setError(field, errorMessage, errorsList);
                        errorsCount = errorsCount + 1;
                    }
                });

                // Validate that the expiryDateTime is not in the past
                if (isDateTimeInPast(this.state.fields.expiryDate, this.state.fields.expiryTime)) {
                    errorsList = setError(
                        'expiryDate',
                        'Expiry date/time cannot be in the past.',
                        errorsList,
                    );
                    errorsCount++;
                }

                const formValid = errorsCount === 0;
                this.setState({
                    formValid,
                    errors: errorsList,
                    isShowAlert,
                });

                // setState (above) doesn't update state synchronously
                // so the handlSubmit is expecting to know if the form is valid,
                // but when this function finishes state hasn't updated yet and the form
                // is still invalid. Returning formValid let's handleSubmit to continue immediatelly.
                // However, this.state.formValid is used for showing errors too, so it is still needed.
                return formValid;
            };

            handleSubmit = async () => {
                const formValid = this.validateFields();

                // Validate the form
                if (!formValid) {
                    return;
                }

                this.handleToggleLoading(true);

                let locationCoordinates = {};
                let locationType;
                let locationPartialMatch;

                const {
                    configStore: { org, clientKeys },
                } = this.props.RootStore;

                // Get job coordinates
                if (this.state.fields.jobType.value === 'homeVisit' && clientKeys.googleMaps) {
                    const fullAddress = `${this.state.fields.addressLine1}, ${this.state.fields.addressLine2}, ${this.state.fields.town}, ${this.state.fields.postCode}, United Kingdom`;
                    let geoLocation;

                    try {
                        geoLocation = await getCoordinates(fullAddress, clientKeys.googleMaps);

                        const [result] = geoLocation.results;

                        if (result) {
                            locationCoordinates = result.geometry.location;
                            locationType = result.geometry.location_type;
                            locationPartialMatch = result.geometry.partial_match;
                        }
                    } catch (err) {
                        console.error('Error getting coordinates', err);
                        AppToaster.show({
                            message: (
                                <>
                                    Sorry, there was a problem getting address information for the
                                    job. Please try again or contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            ),
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                }

                // Prepare the data for sending to the API
                let jobData = getAPIReadyJobData(
                    this.state,
                    locationCoordinates,
                    org,
                    this.props.actionType,
                );

                // Send job data to the API
                if (this.props.actionType === 'create') {
                    try {
                        const { hcpNotifiedCount } = await this.props.RootStore.jobsStore.createJob(
                            jobData,
                        );
                        AppToaster.show({
                            message: `The job has been created successfully and ${hcpNotifiedCount} ${
                                hcpNotifiedCount === 1 ? 'HCP was' : 'HCPs were'
                            } notified.`,
                            intent: 'success',
                        });
                    } catch (err) {
                        const message = (
                            <>
                                Sorry, there was a problem creating the job. Please try again or
                                contact{' '}
                                <a href="mailto:support@docabode.com">support@docabode.com</a> for
                                help.
                            </>
                        );
                        AppToaster.show({
                            message: message,
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                } else if (this.props.actionType === 'edit') {
                    jobData.id = this.props.match.params.id;

                    try {
                        const {
                            hcpNotifiedCount,
                        } = await this.props.RootStore.jobsStore.reSubmitJob(jobData);
                        AppToaster.show({
                            message: `The job has been re-submitted successfully and ${hcpNotifiedCount} ${
                                hcpNotifiedCount === 1 ? 'HCP was' : 'HCPs were'
                            } notified.`,
                            intent: 'success',
                        });
                    } catch (err) {
                        AppToaster.show({
                            message: (
                                <>
                                    Sorry, there was a problem updating the job. Please try again or
                                    contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            ),
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                }

                // If the location of the job is not a good match, let the user know
                if (locationType === 'APPROXIMATE' || locationPartialMatch === true) {
                    AppToaster.show({
                        message:
                            'There was not an exact match on the address for the submitted job, please verify that its location is correct.',
                        intent: 'warning',
                    });
                }

                this.props.RootStore.jobsStore.setJobs();
                this.goToHome();
            };

            handlePopulate = (fields) => {
                let state = this.state;
                _.forOwn(fields, (field, key) => {
                    if (field) {
                        // Simple Dates
                        if (key === 'dateOfBirth') {
                            state.fields[key] = new Date(field);

                            // Complex Date/time fields
                        } else if (
                            key === 'startDateTime' ||
                            key === 'expiryDateTime' ||
                            key === 'endDateTime'
                        ) {
                            state.fields[key.replace('Date', '')] = new Date(field);
                            state.fields[key.replace('Time', '')] = new Date(field);

                            // Price
                        } else if (key === 'priceInPence') {
                            const price = formatPenceToPounds(field);
                            this._handlePriceChange(price, 'price');
                            state.fields['price'] = price;

                            // Single-selects
                        } else if (
                            key === 'jobType' ||
                            key === 'disposition' ||
                            key === 'gender' ||
                            key === 'practice' ||
                            key === 'blockSize'
                        ) {
                            let data = this.props.RootStore.lovsStore[key];
                            let identifier = 'value';
                            if (key === 'practice') {
                                // Lookup of practices is executed against the ODS API
                                // The reverse lookup when editing patients needs to ensure the PracticeSelect has the currently set value available, otherwise the field is blanl
                                if (field != null) {
                                    data = formatPractices([splitPracticeDetails(field)]);
                                } else {
                                    data = [];
                                }
                            }
                            if (key === 'blockSize') {
                                identifier = 'label';
                                if (data) {
                                    data = data.hours.units;
                                } else {
                                    data = [{ label: field, value: field }];
                                }
                            }
                            _.forOwn(data, (lovValue) => {
                                if (field === toJS(lovValue)[identifier]) {
                                    state.fields[key] = toJS(lovValue);
                                }
                            });

                            // Multi-selects
                        } else if (MULTI_SELECT_FIELDS.includes(key)) {
                            let stateFieldValue = [];

                            //Multi-select inputs can have multiple values
                            _.forEach(field, (fieldValue) => {
                                // Get each of the values from the List-of-values store
                                // as each value is in fact a label/value pair and not just a simple string
                                _.forOwn(this.props.RootStore.lovsStore[key], (lovValue) => {
                                    // If the field value exists in the store, use the LOVs store value to populate the field
                                    // If the field value doesn't exist in the store, it's not a valid value and shouldn't be used
                                    if (fieldValue === toJS(lovValue).value) {
                                        stateFieldValue.push(toJS(lovValue));
                                    }
                                });
                            });
                            state.fields[key] = stateFieldValue;
                        } else {
                            state.fields[key] = field;
                        }
                    }
                });

                this.setState(state);
            };

            handleToggleLoading = (status) => {
                this.setState({
                    isLoading: status,
                });
            };

            handleToggleShowAbortDialog = (status) => {
                const prevState = this.state.isAbortDialogShown;
                this.setState({
                    isAbortDialogShown: !prevState,
                });
            };

            handleCancel = () => {
                this.goToHome();
            };

            redirectUponSuccessfulCancel = () => {
                // Indicate a loading state
                this.handleToggleLoading(true);

                // Ensure the latest job details are set
                this.props.RootStore.jobsStore.setJobs();

                // Trigger a redirect to the Map View
                const url = `/on-demand/${this.props.match.params.tab}/map`;
                this.props.history.push(url);

                // Stop the loading state
                this.handleToggleLoading(false);
            };

            goToHome = () => {
                const url = `/on-demand/${this.props.match.params.tab}/map`;
                this.props.history.push(url);
            };

            _handleCloseClick = () => {
                if (this.props.location.state && this.props.location.state.closeModal) {
                    return this.props.history.goBack();
                }

                this.goToHome();
            };

            _isVisible = (field) => {
                const visible = this.state.visible[field];
                return (
                    visible === true ||
                    (Array.isArray(visible) && visible.includes(this.state.fields.jobType.value))
                );
            };

            _isRequired = (field) => {
                const { required } = this.state.validation[field];
                return (
                    required === true ||
                    (Array.isArray(required) && required.includes(this.state.fields.jobType.value))
                );
            };

            _requiredLabel = (field) => {
                return this._isRequired(field) ? '(required)' : '';
            };

            _filterHcpsByType = (users) =>
                users
                    .filter(
                        (user) =>
                            Array.isArray(this.state.fields.hcpType) &&
                            this.state.fields.hcpType.some((type) =>
                                user.hcpTypes.includes(type.value),
                            ),
                    )
                    .sort((a, b) => {
                        // Sort by surname
                        // Split value (userId) by '.' and sort by comparing last item
                        return a.value.split('.').pop().localeCompare(b.value.split('.').pop());
                    });

            _handleHcpTypeChange = (event) => {
                this.handleInputChange(event, 'hcpType', () => {
                    const allowedHcps = this._filterHcpsByType(this.state.fields.nominatedHcps);
                    this.handleInputChange(allowedHcps, 'nominatedHcps');
                });
            };

            _sortHcpTypes = (hcps) =>
                [...hcps].sort((a, b) => {
                    let aOrder = a.hasOwnProperty('order');
                    let bOrder = b.hasOwnProperty('order');
                    if (aOrder && bOrder) {
                        if (a.order > b.order) return 1;
                        if (a.order < b.order) return -1;
                        return a.label.localeCompare(b.label);
                    }
                    // Return items with an order first otherwise compare label
                    return aOrder ? -1 : bOrder ? 1 : a.label.localeCompare(b.label);
                });

            _handlePriceChange = (value) => {
                this.setState({ priceHasChanged: true });
                if (this.defaultShiftPriceDisposer) {
                    this.defaultShiftPriceDisposer();
                }

                this.handleInputChange(value, 'price', () => {
                    const {
                        blockSize: { value: selectedBlockSize },
                    } = this.state.fields;
                    const { blockSize } = this.props.RootStore.lovsStore;
                    if (!blockSize) return;
                    const [unit] = blockSize.hours.prices.filter(
                        (price) => price.value * selectedBlockSize === value,
                    );
                    this.handleInputChange(unit || { label: '', value: null }, 'unitPrice');
                });
            };

            _computePrice = (event, field) => {
                this.handleInputChange(event, field, () => {
                    const {
                        blockSize: { value: blockSize },
                        unitPrice: { value: unitPrice },
                    } = this.state.fields;

                    if (blockSize && unitPrice) {
                        this.handleInputChange(blockSize * unitPrice, 'price');
                    }
                });
            };

            _handlePracticeListChange = () => {
                const practiceFieldDefault = getFieldsDefaultState().fields.practice;
                this.setState({
                    ...this.state,
                    fields: { ...this.state.fields, practice: practiceFieldDefault },
                });
                if (this.state.practiceList === 'GP_PRACTICES') {
                    this.setState({ practiceList: 'PRESET_ADDRESSES' });
                } else if (this.state.practiceList === 'PRESET_ADDRESSES') {
                    this.setState({ practiceList: 'GP_PRACTICES' });
                }
            };

            _renderFooter() {
                if (this.props.actionType === 'create') {
                    return (
                        <>
                            <Button large onClick={this.handleCancel} icon="cross">
                                Cancel
                            </Button>
                            <Button intent="primary" large onClick={this.handleSubmit} icon="tick">
                                Submit
                            </Button>
                        </>
                    );
                }

                if (this.props.actionType === 'edit') {
                    // These labels will be used in the dialog to set archiving reasons
                    const abortAlertLabels = {
                        header: 'Are you sure you want to abort this job?',
                        abortReason: 'Abort reason',
                        abortNotes: 'Abort notes',
                        explanatoryText: null,
                        callouts: 'You must also ensure that any external case details are updated',
                        confirmActionButton: 'Abort',
                        cancelActionButton: 'Cancel',
                    };

                    // We need to get the job details to pass it to the abort dialog
                    const { id } = this.props.match.params;
                    const job = this.props.RootStore.jobsStore.getJob(id);

                    return (
                        <>
                            <AbortJobDialog
                                showAbortPrompt={this.state.isAbortDialogShown}
                                setShowAbortPrompt={this.handleToggleShowAbortDialog}
                                job={job}
                                abortAlertLabels={abortAlertLabels}
                                onConfirmRedirectAction={this.redirectUponSuccessfulCancel}
                            />

                            <Button
                                intent="danger"
                                large
                                onClick={this.handleToggleShowAbortDialog}
                                icon="ban-circle"
                            >
                                Abort
                            </Button>
                            <Button intent="primary" large onClick={this.handleSubmit} icon="redo">
                                Re-Submit
                            </Button>
                        </>
                    );
                }

                return null;
            }

            render() {
                const { lovsStore } = this.props.RootStore;
                const jobTypeOptions = lovsStore.jobType;
                return (
                    <Modal
                        title={this.props.title}
                        {...this.props}
                        footer={this._renderFooter()}
                        onClose={this._handleCloseClick}
                    >
                        {this.state.isLoading ? <Loader /> : ''}
                        <Row>
                            {/*
                        =======================================
                        LEFT COLUMN
                        =======================================
                    */}

                            <Col xl={6}>
                                {/* EXTERNAL JOB REFERENCE */}
                                {this._isVisible('systmOneRef') && (
                                    <FormGroup
                                        label="External Job Reference"
                                        labelFor="systmOneRef"
                                        labelInfo={this._requiredLabel('systmOneRef')}
                                    >
                                        <InputGroup
                                            id="systmOneRef"
                                            autoComplete="new-password"
                                            large
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            value={this.state.fields.systmOneRef}
                                        />
                                        <Error errors={this.state.errors.systmOneRef} />
                                    </FormGroup>
                                )}

                                <FormGroup
                                    label="Job Type"
                                    labelFor="jobType"
                                    labelInfo={this._requiredLabel('jobType')}
                                >
                                    <Select
                                        options={jobTypeOptions}
                                        id="jobType"
                                        onChange={(e) => this.handleInputChange(e, 'jobType')}
                                        value={this.state.fields.jobType}
                                    />
                                    <Error errors={this.state.errors.jobType} />
                                </FormGroup>

                                {/* DISPOSITION */}
                                {this._isVisible('disposition') && (
                                    <FormGroup
                                        label="Disposition"
                                        labelFor="disposition"
                                        labelInfo={this._requiredLabel('disposition')}
                                    >
                                        <Select
                                            options={lovsStore.disposition}
                                            id="disposition"
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'disposition')
                                            }
                                            value={this.state.fields.disposition}
                                        />
                                        <Error errors={this.state.errors.disposition} />
                                    </FormGroup>
                                )}

                                {/* BLOCK SIZE */}
                                {this._isVisible('blockSize') && (
                                    <FormGroup
                                        label="Block Size"
                                        labelFor="blockSize"
                                        labelInfo={this._requiredLabel('blockSize')}
                                    >
                                        {lovsStore.blockSize ? (
                                            <Select
                                                options={lovsStore.blockSize.hours.units}
                                                id="blockSize"
                                                onChange={(e) => this._computePrice(e, 'blockSize')}
                                                value={this.state.fields.blockSize}
                                            />
                                        ) : (
                                            <InputGroup
                                                id="blockSize"
                                                autoComplete="new-password"
                                                large
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        {
                                                            label: e.target.value,
                                                            value: e.target.value,
                                                        },
                                                        'blockSize',
                                                    )
                                                }
                                                value={this.state.fields.blockSize.label}
                                            />
                                        )}
                                        <Error errors={this.state.errors.blockSize} />
                                    </FormGroup>
                                )}

                                {/* FIRST NAME */}
                                {this._isVisible('nhsNumber') && (
                                    <FormGroup
                                        label="NHS Number"
                                        labelFor="nhsNumber"
                                        labelInfo={this._requiredLabel('nhsNumber')}
                                    >
                                        <InputGroup
                                            id="nhsNumber"
                                            autoComplete="new-password"
                                            large
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            value={this.state.fields.nhsNumber}
                                        />
                                        <Error errors={this.state.errors.nhsNumber} />
                                    </FormGroup>
                                )}

                                <Row>
                                    <Col md={6}>
                                        {/* FIRST NAME */}
                                        {this._isVisible('firstName') && (
                                            <FormGroup
                                                label="First Name"
                                                labelFor="firstName"
                                                labelInfo={this._requiredLabel('firstName')}
                                            >
                                                <InputGroup
                                                    id="firstName"
                                                    autoComplete="new-password"
                                                    large
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    value={this.state.fields.firstName}
                                                />
                                                <Error errors={this.state.errors.firstName} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* LAST NAME */}
                                        {this._isVisible('lastName') && (
                                            <FormGroup
                                                label="Last Name"
                                                labelFor="lastName"
                                                labelInfo={this._requiredLabel('lastName')}
                                            >
                                                <InputGroup
                                                    id="lastName"
                                                    autoComplete="new-password"
                                                    large
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    value={this.state.fields.lastName}
                                                />
                                                <Error errors={this.state.errors.lastName} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        {/* DATE OF BIRTH */}
                                        {this._isVisible('dateOfBirth') && (
                                            <FormGroup label="Date of Birth" labelFor="dateOfBirth">
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'dateOfBirth');
                                                    }}
                                                    minDate={this.state.helpers.minDate}
                                                    value={this.state.fields.dateOfBirth?.toISOString()}
                                                />
                                                <Error errors={this.state.errors.dateOfBirth} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* AGE */}
                                        {this._isVisible('age') && (
                                            <FormGroup label="Age" labelFor="age">
                                                <NumericInput
                                                    id="age"
                                                    autoComplete="new-password"
                                                    onValueChange={(value) =>
                                                        this.handleInputChange(value, 'age')
                                                    }
                                                    value={
                                                        this.state.fields.age !== null
                                                            ? this.state.fields.age
                                                            : ''
                                                    }
                                                    large
                                                    fill
                                                />
                                                <Error errors={this.state.errors.age} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        {/* GENDER */}
                                        {this._isVisible('gender') && (
                                            <FormGroup
                                                label="Gender"
                                                labelFor="gender"
                                                labelInfo={this._requiredLabel('gender')}
                                            >
                                                <Select
                                                    options={lovsStore.gender}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'gender')
                                                    }
                                                    value={this.state.fields.gender}
                                                />
                                                <Error errors={this.state.errors.gender} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* CONTACT NUMBER */}

                                        {this._isVisible('contactNumber') && (
                                            <FormGroup
                                                label="Contact Number"
                                                labelFor="contactNumber"
                                                labelInfo={this._requiredLabel('contactNumber')}
                                            >
                                                <ReactPhoneInput
                                                    inputProps={{
                                                        id: 'contactNumber',
                                                        autoComplete: 'new-password',
                                                    }}
                                                    country="gb"
                                                    onlyCountries={['gb']}
                                                    onChange={(value) =>
                                                        this.handleInputChange(
                                                            value,
                                                            'contactNumber',
                                                        )
                                                    }
                                                    value={this.state.fields.contactNumber}
                                                    countryCodeEditable={false}
                                                    specialLabel=""
                                                />
                                                <Error errors={this.state.errors.contactNumber} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                {/* PRACTICE */}
                                {this._isVisible('prefillPractice') && (
                                    <div className="prefill__practice">
                                        <RadioGroup
                                            label="Prefill address from:"
                                            labelFor="practiceList"
                                            onChange={this._handlePracticeListChange}
                                            selectedValue={this.state.practiceList}
                                            inline
                                        >
                                            <Radio label="GP Practices" value="GP_PRACTICES" />
                                            <Radio
                                                label="Preset Addresses"
                                                value="PRESET_ADDRESSES"
                                            />
                                        </RadioGroup>
                                        <FormGroup
                                            label={
                                                this.state.practiceList === 'GP_PRACTICES'
                                                    ? 'Practice'
                                                    : 'Preset Address'
                                            }
                                            labelFor="practice"
                                            labelInfo={this._requiredLabel('practice')}
                                        >
                                            {this.state.practiceList === 'GP_PRACTICES' ? (
                                                <PracticeSelect
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'practice')
                                                    }
                                                    value={this.state.fields.practice}
                                                    isClearable={true}
                                                />
                                            ) : (
                                                <Select
                                                    options={this.props.RootStore.configStore.presetAddresses.map(
                                                        (practice) => ({
                                                            label: practice.name,
                                                            value: practice.name,
                                                        }),
                                                    )}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'practice')
                                                    }
                                                    value={this.state.fields.practice}
                                                />
                                            )}
                                            <Error errors={this.state.errors.practice} />
                                        </FormGroup>
                                    </div>
                                )}

                                {/* ADDRESS LINE 1 */}
                                {this._isVisible('addressLine1') && (
                                    <FormGroup
                                        label="Address Line 1"
                                        labelFor="addressLine1"
                                        labelInfo={this._requiredLabel('addressLine1')}
                                    >
                                        <InputGroup
                                            id="addressLine1"
                                            autoComplete="new-password"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            large
                                            value={this.state.fields.addressLine1}
                                        />
                                        <Error errors={this.state.errors.addressLine1} />
                                    </FormGroup>
                                )}

                                {/* ADDRESS LINE 2 */}
                                {this._isVisible('addressLine2') && (
                                    <FormGroup label="Address Line 2" labelFor="addressLine2">
                                        <InputGroup
                                            id="addressLine2"
                                            autoComplete="new-password"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            large
                                            value={this.state.fields.addressLine2}
                                        />
                                        <Error errors={this.state.errors.addressLine2} />
                                    </FormGroup>
                                )}

                                <Row>
                                    <Col md={7}>
                                        {/* TOWN / CITY */}
                                        {this._isVisible('town') && (
                                            <FormGroup
                                                label="Town / City"
                                                labelFor="town"
                                                labelInfo={this._requiredLabel('town')}
                                            >
                                                <InputGroup
                                                    id="town"
                                                    autoComplete="new-password"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    large
                                                    value={this.state.fields.town}
                                                />
                                                <Error errors={this.state.errors.town} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={5}>
                                        {/* POST CODE */}
                                        {this._isVisible('postCode') && (
                                            <FormGroup
                                                label="Post Code"
                                                labelFor="postCode"
                                                labelInfo={this._requiredLabel('postCode')}
                                            >
                                                <InputGroup
                                                    id="postCode"
                                                    autoComplete="new-password"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    large
                                                    value={this.state.fields.postCode}
                                                />
                                                <Error errors={this.state.errors.postCode} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            {/*
                        =======================================
                        RIGHT COLUMN
                        =======================================
                    */}

                            <Col xl={6}>
                                {/* PRACTICE */}
                                {this._isVisible('practice') && (
                                    <FormGroup
                                        label="Practice"
                                        labelFor="practice"
                                        labelInfo={this._requiredLabel('practice')}
                                    >
                                        <PracticeSelect
                                            onChange={(e) => this.handleInputChange(e, 'practice')}
                                            value={this.state.fields.practice}
                                            placeholder="Start typing the practice name to see results"
                                        />
                                        <Error errors={this.state.errors.practice} />
                                    </FormGroup>
                                )}

                                {/* LANGUAGE */}
                                {this._isVisible('languagesSpoken') && (
                                    <FormGroup label="Language" labelFor="languagesSpoken">
                                        <Select
                                            options={lovsStore.languagesSpoken}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'languagesSpoken')
                                            }
                                            value={this.state.fields.languagesSpoken}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.languagesSpoken} />
                                    </FormGroup>
                                )}

                                {/* SPECIALITY */}
                                {this._isVisible('speciality') && (
                                    <FormGroup label="Speciality" labelFor="speciality">
                                        <Select
                                            options={lovsStore.speciality}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'speciality')
                                            }
                                            value={this.state.fields.speciality}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.speciality} />
                                    </FormGroup>
                                )}

                                {/* FLAGS */}
                                {this._isVisible('flags') && (
                                    <FormGroup label="Flags" labelFor="flags">
                                        <Select
                                            options={lovsStore.flags}
                                            onChange={(e) => this.handleInputChange(e, 'flags')}
                                            value={this.state.fields.flags}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.flags} />
                                    </FormGroup>
                                )}

                                {/* NOTES */}
                                {this._isVisible('notes') && (
                                    <FormGroup label="Notes" labelFor="notes">
                                        <TextArea
                                            id="notes"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            fill
                                            large
                                            value={this.state.fields.notes}
                                            rows={9}
                                        />
                                        <Error errors={this.state.errors.notes} />
                                    </FormGroup>
                                )}

                                {/* HCP TYPE */}
                                {this._isVisible('hcpType') && (
                                    <FormGroup
                                        label="HCP Type"
                                        labelFor="hcpType"
                                        labelInfo={this._requiredLabel('hcpType')}
                                    >
                                        <Select
                                            options={this._sortHcpTypes(lovsStore.hcpType)}
                                            onChange={(e) => this._handleHcpTypeChange(e)}
                                            value={this.state.fields.hcpType}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.hcpType} />
                                    </FormGroup>
                                )}

                                {/* NOMINATED HCPS */}
                                {this._isVisible('nominatedHcps') && (
                                    <FormGroup
                                        label="Nominated HCPs"
                                        labelFor="nominatedHcps"
                                        helperText="Only notifies and makes the job available to the selected HCPs. Leave blank to post job to all relevant HCPs."
                                    >
                                        <Select
                                            options={this._filterHcpsByType(
                                                lovsStore.nominatedHcps,
                                            )}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'nominatedHcps')
                                            }
                                            value={this.state.fields.nominatedHcps}
                                            isMulti
                                            noOptionsMessage={({ inputValue }) =>
                                                inputValue
                                                    ? 'No results'
                                                    : 'Please select at least one HCP type above to see results'
                                            }
                                        />
                                        <Error errors={this.state.errors.nominatedHcps} />
                                    </FormGroup>
                                )}

                                {/* PRICE */}
                                {this._isVisible('price') && (
                                    <Row>
                                        {/* UNIT PRICE */}
                                        {this._isVisible('blockSize') && lovsStore.blockSize && (
                                            <Col md={6}>
                                                <FormGroup label="Unit price" labelFor="unitPrice">
                                                    <Select
                                                        options={lovsStore.blockSize.hours.prices}
                                                        id="unitPrice"
                                                        onChange={(e) =>
                                                            this._computePrice(e, 'unitPrice')
                                                        }
                                                        value={this.state.fields.unitPrice}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md={6}>
                                            <FormGroup
                                                label="Price"
                                                labelFor="price"
                                                labelInfo={this._requiredLabel('price')}
                                            >
                                                <NumericInput
                                                    id="price"
                                                    onValueChange={(value) =>
                                                        this._handlePriceChange(value)
                                                    }
                                                    large
                                                    value={
                                                        this.state.fields.price !== null
                                                            ? this.state.fields.price
                                                            : 0
                                                    }
                                                    fill
                                                />
                                                <Error errors={this.state.errors.price} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup
                                                label="Price Visibility"
                                                labelFor="hidePriceInApp"
                                            >
                                                <Checkbox
                                                    id="hidePriceInApp"
                                                    label="Hide price in app"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.checked,
                                                            'hidePriceInApp',
                                                        )
                                                    }
                                                    checked={this.state.fields.hidePriceInApp}
                                                />
                                                <Error errors={this.state.errors.hidePriceInApp} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={6}>
                                        {/* EXPIRY DATE */}
                                        {this._isVisible('expiryDate') && (
                                            <FormGroup
                                                label="Expiry Date"
                                                labelFor="expiryDate"
                                                labelInfo={this._requiredLabel('expiryDate')}
                                            >
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'expiryDate');
                                                    }}
                                                    maxDate={this.state.helpers.maxDate}
                                                    value={this.state.fields.expiryDate?.toISOString()}
                                                />
                                                <Error errors={this.state.errors.expiryDate} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* END TIME */}
                                        {this._isVisible('expiryTime') && (
                                            <FormGroup
                                                label="Expiry Time"
                                                labelFor="expiryTime"
                                                labelInfo={this._requiredLabel('expiryTime')}
                                            >
                                                <TimePicker
                                                    onChange={(time) =>
                                                        this.handleInputChange(time, 'expiryTime')
                                                    }
                                                    value={this.state.fields.expiryTime}
                                                />
                                                <Error errors={this.state.errors.expiryTime} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal>
                );
            }
        },
    ),
);

export default JobForm;
