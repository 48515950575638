import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Callout } from '@blueprintjs/core';

import HistoricJobListItem from '../modules/jobs/list/HistoricJobListItem';

const Reporting = inject('RootStore')(
    observer(
        class Reporting extends Component {
            handleListItemClick = (jobId, jobType, event) => {
                if (!event.target.href) {
                    this.props.history.push({
                        pathname: `/on-demand/${this.props.match.params.tab}/jobs/${jobId}`,
                        state: { closeModal: true },
                    });
                }
            };

            render() {
                return (
                    <div>
                        <div className="page">
                            <h2 className="h2">Historic Jobs</h2>
                            <div className="historic-jobs-list">
                                {this.props.RootStore &&
                                !this.props.RootStore.jobsStore.historicJobs.length ? (
                                    <Callout intent="primary">
                                        There are no <strong>historic</strong> jobs
                                    </Callout>
                                ) : (
                                    this.props.RootStore.jobsStore.historicJobs.map((job, i) => (
                                        <HistoricJobListItem
                                            data={job}
                                            activeJob={false}
                                            type={'historic'}
                                            onListItemClick={this.handleListItemClick}
                                            key={job.systmOneRef + i}
                                            {...this.props}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default Reporting;
