import moment from 'moment';
import { FC } from 'react';

import { ViewToShow } from '../../../../../constants/mainConst';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { IPatientExtended } from './ListView';

import { EnumJobContainer } from '../calendar/Job/JobTypes';
import WarningIcons from '../calendar/Job/WarningIcons';
import { useJobS1SyncStatusViewModel } from '../useJobS1SyncStatusViewModel';
import { useListViewRowViewModel } from './useListViewRowViewModel';

interface IPropsDisplayDate {
    date?: string | null;
}

interface ListViewRowInterface {
    job: IPatientExtended;
    index: number;
}

// date formatter for arrived time, end time, planned time.
const DisplayDate: FC<IPropsDisplayDate> = ({ date }): JSX.Element | null => {
    if (!date) return null;
    return (
        <>
            {moment(date).format('DD/MM/YYYY')}
            <br></br>
            {moment(date).format('hh:mm A')}
        </>
    );
};

export const ListViewRow: FC<ListViewRowInterface> = ({ job, index }) => {
    const {
        focusJob,
        focused,
        pressed,
        isBuddyRow,
        nhsNumber,
        patientName,
        dateOfBirthAge,
        gender,
        postCode,
        pathway,
        service,
        plannedStartDateTime,
        plannedDuration,
        arrivedDateTime,
        finishedOrAbortedDateTime,
        jobStatus,
        jobStatusClassname,
        hcpName,
    } = useListViewRowViewModel(job);

    const {
        isS1SyncEnabled,
        getS1SyncStatusDisplayLabel,
        getS1SyncStatusClassname,
    } = useJobS1SyncStatusViewModel(job);

    return (
        <tr
            role="button"
            aria-pressed={pressed}
            tabIndex={index + 1}
            onKeyDown={(key) => {
                if (key.code === 'Enter') {
                    focusJob(job.id, isBuddyRow);
                }
            }}
            onClick={() => {
                focusJob(job.id, isBuddyRow);
            }}
            className={focused}
            key={'listview-content-' + index}
        >
            <td className="whiteSpace__nowrap">{nhsNumber}</td>
            <td>{patientName}</td>
            <td className="whiteSpace__nowrap">{dateOfBirthAge}</td>
            <td className="ucr-listview__child--uppercase">{gender}</td>
            <td>{postCode}</td>
            <td>{pathway}</td>
            <td>{service}</td>
            <td>
                <DisplayDate date={plannedStartDateTime} />
            </td>
            <td>{plannedDuration}</td>
            <td>
                <DisplayDate date={arrivedDateTime} />
            </td>
            <td>
                <DisplayDate date={finishedOrAbortedDateTime} />
            </td>
            <td>{hcpName}</td>
            <td>
                <span className={jobStatusClassname}>{jobStatus}</span>
            </td>
            <td>
                <WarningIcons
                    job={job}
                    isBuddy={isBuddyRow}
                    container={EnumJobContainer.LIST}
                    showTooltip={true}
                />
            </td>
            <ConditionalDisplay show={isS1SyncEnabled}>
                <td>
                    <span className={getS1SyncStatusClassname(ViewToShow.VISITS_LIST, isBuddyRow)}>
                        {getS1SyncStatusDisplayLabel(isBuddyRow)}
                    </span>
                </td>
            </ConditionalDisplay>
        </tr>
    );
};
