import { WarningType } from '../../../../../interfaces/ucr';

type StringOrBoolean = string | boolean;

interface WarningInterface {
    warnings: WarningType[];
    stepWarnings?: string[];
    values?: any;
}

interface AffectedFieldsInterface {
    buddyId?: StringOrBoolean;
    hcpId?: StringOrBoolean;
    visitDate?: StringOrBoolean;
    startTime?: StringOrBoolean;
    nhsNumber?: StringOrBoolean;
}

export const warningHighlight = ({
    warnings,
    stepWarnings,
    values,
}: WarningInterface): AffectedFieldsInterface => {
    const affectedFields: AffectedFieldsInterface = {
        buddyId: false,
        hcpId: false,
        visitDate: false,
        startTime: false,
        nhsNumber: false,
    };

    if (stepWarnings && stepWarnings.length > 0) {
        stepWarnings.forEach((warning) => {
            if (warning === 'Planned start time of the job is in the past!') {
                affectedFields.startTime = warning;
                affectedFields.visitDate = warning;
            }
        });
    }

    if (warnings && warnings.length > 0) {
        warnings.forEach((warning) => {
            if (
                warning.category === 'HCP_AVAILABILITY_CONFLICT' ||
                warning.category === 'HCP_SCHEDULING_CONFLICT'
            ) {
                if (values.hcpId === warning?.data?.hcpId) {
                    affectedFields.hcpId = warning.message;
                } else if (values.buddyId === warning?.data?.hcpId) {
                    affectedFields.buddyId = warning.message;
                }
            }
            if (warning.category === 'PATIENT_SCHEDULING_CONFLICT') {
                affectedFields.nhsNumber = warning.message;
                affectedFields.startTime = warning.message;
                affectedFields.visitDate = warning.message;
            }
        });
    }

    return affectedFields;
};
