import React from 'react';
import { observer } from 'mobx-react';
import { Alert, Button } from '@blueprintjs/core';
import { S1ManagementViewModel } from './S1ManagementViewModel';

const S1Management = () => {
    const {
        confirmLocks,
        confirmQueue,
        handleSelectQueue,
        handleSelectLocks,
        handlePurgeQueue,
        handlePurgeLocks,
    } = S1ManagementViewModel();

    return (
        <div className="page">
            <h1 className="h1">SystmOne</h1>

            <p>
                *Note* Before purging the message queue or job locks, please ensure to close all
                running SystmOne clients.
            </p>
            <div className="flex">
                <p className="font-bold">Would you like to purge the message queue?</p>
                <Button intent="danger" large type="button" onClick={() => handleSelectQueue()}>
                    Purge Queue
                </Button>
            </div>
            <br />
            <div className="flex">
                <p className="font-bold">Would you like to purge the job locks?</p>
                <Button intent="danger" large type="button" onClick={() => handleSelectLocks()}>
                    Purge Locks
                </Button>
            </div>

            <Alert
                isOpen={confirmQueue}
                onConfirm={() => handlePurgeQueue()}
                onCancel={() => handleSelectQueue()}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                icon="warning-sign"
                className="dialog--wide"
                intent="danger"
            >
                <h2 className="h2">Important Note</h2>
                <p>Are you sure you want to purge the queue?</p>
            </Alert>

            <Alert
                isOpen={confirmLocks}
                onConfirm={() => handlePurgeLocks()}
                onCancel={() => handleSelectLocks()}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                icon="warning-sign"
                className="dialog--wide"
                intent="danger"
            >
                <h2 className="h2">Important Note</h2>
                <p>Are you sure you want to purge the locks?</p>
            </Alert>
        </div>
    );
};

export default observer(S1Management);
