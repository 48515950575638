import moment from 'moment';
import { formatDateTime } from '../../helpers/formatData';

export const isDateTimeInPast = (date, time) => {
    if (date !== null && time !== null && !isNaN(date.valueOf())) {
        const dateTime = formatDateTime(date, time);
        const now = moment();
        if (now.isAfter(dateTime)) {
            return true;
        }
    }
    return false;
};
