import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { Redirect } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';

import Loader from '../../modules/helpers/Loader';
import FileInput from '../../modules/forms/FileInput';
import Error from '../../modules/forms/Error';
import { formatDisplayDateTime } from '../../modules/helpers/formatData';
import AppToaster from '../../modules/helpers/Toaster';

const defaultLinkErrors = () => ({
    title: [],
    url: [],
});

const Onboarding = inject('RootStore')(
    observer(
        class Onboarding extends Component {
            state = {
                downloading: {},
                fields: {
                    title: '',
                    url: '',
                },
                submittingLinks: false,
                linkErrors: defaultLinkErrors(),
                links: [],
            };

            componentDidMount() {
                const {
                    onboardingsStore: { fetchOwnOnboarding },
                    configStore: { onboardingId },
                } = this.props.RootStore;

                when(
                    () => onboardingId,
                    async () => {
                        await fetchOwnOnboarding(onboardingId);
                        this._updateLinks();
                    },
                );
            }

            _updateLinks = () =>
                this.setState({
                    links: this.props.RootStore.onboardingsStore.ownOnboarding.links || [],
                });

            _handleDownloadFileClick = async (key) => {
                this.setState({
                    downloading: {
                        ...this.state.downloading,
                        [key]: true,
                    },
                });

                const url = await this.props.RootStore.onboardingsStore.downloadFile(
                    key,
                    'organisations',
                );
                window.open(url);

                this.setState({
                    downloading: {
                        ...this.state.downloading,
                        [key]: false,
                    },
                });
            };

            _handleInputChange = (event) => {
                const { id, value } = event.target;
                this.setState({ fields: { ...this.state.fields, [id]: value } });
            };

            _handleAddLink = async (event) => {
                event.preventDefault();

                const {
                    links,
                    fields,
                    fields: { title, url },
                } = this.state;

                const errors = defaultLinkErrors();

                if (title.length === 0) {
                    errors.title = ['This field is required.'];
                }

                console.log(url, !url.startsWith('http://'), !url.startsWith('https://'));

                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    errors.url = ['URL must start with "http://" or "https://"'];
                }

                if (url.length === 0) {
                    errors.url = ['This field is required.'];
                }

                this.setState({ linkErrors: errors });

                if (Object.values(errors).some((error) => error.length > 0)) {
                    return;
                }

                this.setState({
                    links: [...links, { title, url }],
                    fields: { ...fields, title: '', url: '' },
                });
            };

            _handleRemoveLink = (linkToRemove) =>
                this.setState({
                    links: this.state.links.filter((link) => link !== linkToRemove),
                });

            _handleSaveLinks = async () => {
                this.setState({ submittingLinks: true });

                const { links } = this.state;

                const { success } = await this.props.RootStore.onboardingsStore.updateOwnOnboarding(
                    {
                        links,
                    },
                );

                if (success) {
                    AppToaster.show({
                        message: 'Links successfully updated.',
                        intent: 'success',
                    });
                    this._updateLinks();
                } else {
                    AppToaster.show({
                        message: 'Unable to update links, please try again.',
                        intent: 'danger',
                    });
                }

                this.setState({ submittingLinks: false });
            };

            render() {
                const {
                    RootStore: {
                        configStore: { isFeatureEnabled },
                        onboardingsStore: {
                            ownOnboarding,
                            getFileUploadParams,
                            uploadFile,
                            confirmFileUpload,
                        },
                    },
                } = this.props;

                if (!isFeatureEnabled('invites')) {
                    return <Redirect to="/page-not-found" />;
                }

                if (!ownOnboarding) {
                    return <Loader />;
                }

                const { files = [], onboardingId } = ownOnboarding;

                const { fields, downloading, links, linkErrors, submittingLinks } = this.state;

                return (
                    <div className="page">
                        <h1 className="h1">Organisation Details</h1>
                        <h2 className="h2">Files</h2>
                        <div className="onboarding__files">
                            <Callout intent="primary" className="onboarding__callout">
                                These assets will be available to any HCP interested in joining your
                                organisation, so please do not upload any sensitive materials.
                            </Callout>
                            {files.length > 0 && (
                                <table className="bp5-html-table bp5-html-table-striped links">
                                    <thead>
                                        <tr>
                                            <th>File name</th>
                                            <th>Uploaded</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {files.map(({ fileName, key, uploadedAt }) => (
                                            <tr key={key}>
                                                <td>{fileName}</td>
                                                <td>{formatDisplayDateTime(uploadedAt)}</td>
                                                <td>
                                                    <Button
                                                        onClick={() =>
                                                            this._handleDownloadFileClick(key)
                                                        }
                                                        icon="cloud-download"
                                                        intent="primary"
                                                        loading={downloading[key]}
                                                    >
                                                        Download
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <FileInput
                                onboardingId={onboardingId}
                                getFileUploadParams={getFileUploadParams}
                                uploadFile={uploadFile}
                                confirmFileUpload={confirmFileUpload}
                            />
                        </div>
                        <h2 className="h2">Links</h2>
                        <form onSubmit={this._handleAddLink}>
                            <table className="bp5-html-table bp5-html-table-striped links">
                                <tbody>
                                    {links.map((link, index) => (
                                        <tr key={`${link.url}-${index}`}>
                                            <td>
                                                <a
                                                    href={link.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {link.title}
                                                </a>{' '}
                                                - <span className="links__url">{link.url}</span>
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() => this._handleRemoveLink(link)}
                                                    icon="cross"
                                                    intent="danger"
                                                    disabled={submittingLinks}
                                                >
                                                    Remove
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <div className="links__form">
                                                <FormGroup className="links__input">
                                                    <InputGroup
                                                        id="title"
                                                        value={fields.title}
                                                        onChange={this._handleInputChange}
                                                        placeholder="Title e.g. Online training"
                                                        disabled={submittingLinks}
                                                        fill
                                                    />
                                                    <Error errors={linkErrors.title} />
                                                </FormGroup>
                                                <FormGroup className="links__input">
                                                    <InputGroup
                                                        id="url"
                                                        value={fields.url}
                                                        onChange={this._handleInputChange}
                                                        placeholder="URL e.g. https://www.example.com/training"
                                                        disabled={submittingLinks}
                                                        fill
                                                    />
                                                    <Error errors={linkErrors.url} />
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td>
                                            <Button
                                                type="submit"
                                                icon="add"
                                                intent="primary"
                                                disabled={submittingLinks}
                                            >
                                                Add
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        {links !== this.props.RootStore.onboardingsStore.ownOnboarding.links && (
                            <Button
                                onClick={this._handleSaveLinks}
                                icon="tick"
                                intent="success"
                                loading={submittingLinks}
                                large
                            >
                                Save links
                            </Button>
                        )}
                    </div>
                );
            }
        },
    ),
);

export default Onboarding;
