import { S1Message, S1Referral, LinkedVisit } from '@doc-abode/data-models';

import { baseUrl, retryable, headers } from './baseApi';

export type GetS1MessagesParams = {
    paginationToken?: string;
    nhsNumber?: string;
};

export const getS1Messages = (authToken: string, params: GetS1MessagesParams = {}) =>
    retryable<{ messages: S1Message[]; paginationToken?: string }>(() =>
        fetch(`${baseUrl}/s1/messages?${new URLSearchParams(params)}`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const getS1Referrals = (authToken: string, nhsNumber: string) =>
    retryable<{ referrals: S1Referral[]; updatedAt: string }>(() =>
        fetch(`${baseUrl}/s1/patient/${nhsNumber}/referrals`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const syncS1Referrals = (authToken: string, nhsNumber: string) =>
    retryable<{ requestId: string }>(() =>
        fetch(`${baseUrl}/s1/patient/${nhsNumber}/referrals/sync`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const getS1LinkedVisits = (
    authToken: string,
    jobs: { jobId: string; personJobId: string; version: number }[],
) =>
    retryable<{ results: LinkedVisit[] }>(() =>
        fetch(`${baseUrl}/s1/visits/linked`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
            body: JSON.stringify({ jobs }),
        }),
    );

export const purgeQueue = (authToken: string) =>
    retryable<{ statusCode: number }>(() =>
        fetch(`${baseUrl}/s1/queue/purge`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const purgeLocks = (authToken: string) =>
    retryable<{ statusCode: number }>(() =>
        fetch(`${baseUrl}/s1/lock-table/purge`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );
