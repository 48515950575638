import { FormikValues } from 'formik';
import { VisitData } from '../../components/pages/ucr/forms/AddVisit/AddVisitTypes';

const fixDateFields = (values: FormikValues): void => {
    const {
        availableFrom,
        availableTo,
        dateOfBirth,
        dateOfVisit,
        visitDate,
        startTime,
        earliestDateOfVisit,
    } = values;
    values.dateOfBirth = typeof dateOfBirth === 'string' ? new Date(dateOfBirth) : dateOfBirth;
    values.visitDate = visitDate || new Date(dateOfVisit);

    values.availableFrom =
        availableFrom && typeof availableFrom === 'string'
            ? new Date(availableFrom)
            : availableFrom;
    values.availableTo =
        availableTo && typeof availableTo === 'string' ? new Date(availableTo) : availableTo;
    values.earliestDateOfVisit =
        earliestDateOfVisit && typeof earliestDateOfVisit === 'string'
            ? new Date(earliestDateOfVisit)
            : earliestDateOfVisit;
    values.startTime = startTime && typeof startTime === 'string' ? new Date(startTime) : startTime;
};

export const getFollowUpValues = (values: FormikValues): VisitData => {
    const extraFields = [
        'id',
        'itineraryId',
        'arrivedDateTime',
        'buddyArrivedDateTime',
        'buddyFinishedDateTime',
        'controllerAbortedNotes',
        'controllerAbortedReason',
        'withdrawnReason',
        'withdrawnNotes',
        'vaccineBatchNumber',
        'vaccineExpiryDate',
        'coAdministeredWith',
        'consented',
        'dateOfDose1',
        'dateOfDose2',
        'dateOfDose3',
        'dateOfDose4',
        'doseNumber',
        'finishedDateTime',
        'flags',
        'hcpAbortedNotes',
        'hcpAbortedReason',
        'hcpName',
        'manufacturerOfDose1',
        'manufacturerOfDose2',
        'manufacturerOfDose3',
        'manufacturerOfDose4',
        'practice',
        'priceInPence',
        'vaccinationCategory',
        'vaccineManufacturer',
        'vaccineSiteOfAdministration',
        'buddyControllerAbortedReason',
        'buddyControllerAbortedNotes',
        'buddyHcpAbortedReason',
        'buddyHcpAbortedNotes',
        'buddyJobStatus',
        'buddyName',
        'postVisitNotes',
        'postVisitNotesBuddy',
        'markedCompletedByController',
        'markedCompletedByControllerNotes',
        'hcpAbortedDateTime',
        'lastUpdatedDateTime',
        'latitude',
        'longitude',
        'madeCurrentDateTime',
        'buddyMadeCurrentDateTime',
        'abortedFollowUpAction',
        'referredBy',
        '__typename',
    ];
    const fieldsShouldBeString = ['addressLine2', 'addressLine3', 'systmOneRef'];
    fixDateFields(values);
    const visitValues: FormikValues = {
        ...values,
        disposition: 'followUp',
        initialVisit: values.id,
        jobType: 'ucr',
    };

    extraFields.forEach((propName) => propName in visitValues && delete visitValues[propName]);
    fieldsShouldBeString.forEach(
        (propName) => (visitValues[propName] = visitValues[propName] ? visitValues[propName] : ''),
    );
    return visitValues as VisitData;
};

export const getFollowUpAdminTimeValues = (values: FormikValues): Partial<VisitData> => {
    const folowUpAdminTimeData = {
        nhsNumber: values.nhsNumber,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        contactNumber: values.contactNumber,
        additionalContactNumbers: values.additionalContactNumbers,
        dateOfBirth: values.dateOfBirth,
        gender: values.gender,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        addressLine3: values.addressLine3,
        town: values.town,
        postCode: values.postCode,
        languagesSpoken: values.languagesSpoken,
        staffPreferredGender: values.staffPreferredGender,
        previouslyHcpId: values.hcpId,
        previouslyBuddyId: values.buddyId,
    };

    return folowUpAdminTimeData;
};
