import useStores from '../../../../../hook/useStores';

export const useCollapseAndExpandButtonsViewModel = () => {
    const {
        RootStore: {
            ucrStore: { setAllExpandedState },
        },
    } = useStores() as { RootStore: any };

    const collapseAll = () => {
        setAllExpandedState(false);
    };

    const expandAll = () => {
        setAllExpandedState(true);
    };

    return {
        expandAll,
        collapseAll,
    };
};
