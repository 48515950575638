import { LegendOptionV2 } from '@doc-abode/data-models';
import { FC } from 'react';

import {
    AddressWarningIcon,
    EarliestArrival,
    IconCallback,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    IconNote,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../helpers/ucr/icons';
import useStores from '../../../../hook/useStores';

import RootStore from '../../../../stores/RootStore';
import { Panel } from './side';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const DynamicLegendOption = ({ option }: { option: LegendOptionV2 }) => (
    <div className="ucr__legend-panel-sub-section" key={option.color}>
        <div
            className="ucr__legend-panel-square-box-size"
            style={{ backgroundColor: option.color }}
        ></div>
        <p className="ucr__legend-panel-label">{option.label}</p>
    </div>
);

const Legend: FC<IProps> = ({ isShow, closePanel }) => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes, legend },
        },
    } = useStores() as { RootStore: RootStore };

    const allServices = pathways.map((pathway: any) => pathway.services).flat();

    const visitLegend =
        legend.visit?.filter((option: any) =>
            allServices.some((service: any) => service.color === option.color && service.enabled),
        ) || [];

    const adminLegend =
        legend.admin?.filter((option: any) =>
            adminTypes.some((admin: any) => admin.color === option.color && admin.enabled),
        ) || [];

    return (
        <Panel title="Legend" side="right" isShow={isShow} closePanel={closePanel}>
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-legend-wrapper">
                    <h4 className="ucr__legend-panel-titles">Warning indicators</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <IconWarning className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Job has warnings</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <EarliestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Earliest time of arrival breached</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <LatestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Latest time of arrival breached</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNonEnglishSpeaker className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">
                            Assignees do not speak the patient's language
                        </p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <People className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        <p className="ucr__legend-panel-label">Job is missing assignees</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Visit colours</h4>
                    {visitLegend.map((option: any) => (
                        <DynamicLegendOption option={option} key={option.color} />
                    ))}
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-any ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Unresolved patient alert</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Admin colours</h4>
                    {adminLegend.map((option: any) => (
                        <DynamicLegendOption option={option} key={option.color} />
                    ))}

                    <h4 className="ucr__legend-panel-titles">Visit states</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-notStarted ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit not yet started</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-current ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">En-route to patient</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-arrived ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Care started</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-completed ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit completed</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <div className="ucr__legend-panel-square-box-aborted ucr__legend-panel-square-box-size"></div>
                        <p className="ucr__legend-panel-label">Visit cancelled</p>
                    </div>

                    <h4 className="ucr__legend-panel-titles">Additional information</h4>
                    <div className="ucr__legend-panel-sub-section">
                        <IconWarning className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Job has warnings</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <AddressWarningIcon className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">
                            Address details could not be verified
                        </p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <EarliestArrival className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Earliest time of arrival set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <LatestArrival className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Latest time of arrival set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <People className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Double-up job</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconCarRequired className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Job requires a car</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNote className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Pre-job notes set</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconNonEnglishSpeaker className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Patient is a non-English speaker</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconComplexCare className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Complex case</p>
                    </div>
                    <div className="ucr__legend-panel-sub-section">
                        <IconCallback className="ucr__calendar-icon" />
                        <p className="ucr__legend-panel-label">Callback requested</p>
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default Legend;
