import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import { WarningType } from '../../../../../interfaces';
import { JobsContext } from '../../../../../providers';
import { ADMIN_TIME } from '../../forms/AdminTime/AdminTimeConsts';

export interface PanelDetailsVMInterface {
    refreshJobs: () => void;
}

export interface APIValuesInterface {
    id: string;
    jobStatus?: JobStatus;
    buddyJobStatus?: JobStatus;
    lastUpdatedBy: string;
    lastUpdatedDateTime: string;
    version: number;
    hcpId?: string | null;
    hcpName?: string;
    buddyId?: string | null;
    buddyName?: string;
}

export const inactiveStatus = [
    JobStatus.COMPLETED,
    JobStatus.HCP_ABORTED,
    JobStatus.CONTROLLER_ABORTED,
];

export const activeStates = [
    JobStatus.PENDING,
    JobStatus.ACCEPTED,
    JobStatus.CURRENT,
    JobStatus.ARRIVED,
];

export const usePanelDetailsViewModel = (props: PanelDetailsVMInterface) => {
    const {
        RootStore: {
            ucrStore: { focusedJobId },
            ucrStore,
            userStore,
            usersStore,
            configStore,
        },
    } = useStores() as { RootStore: any };

    const jobsContext = useContext(JobsContext);

    const { refreshJobs } = props;
    const { username } = userStore.user;
    const { users } = usersStore;
    const { isFeatureEnabled } = configStore;
    const [patient, setPatient] = useState({} as Patient);
    const [showDetailsAmendDialog, setShowDetailsAmendDialog] = useState(false);

    const {
        focusedUser,
        loadingWarnings,
        loadingPDSData,
        loadingJobAlerts,
        warnings,
        clearLocalPatientData,
        clearPDSData,
        openAlert,
        closeAlert,
        getPDSData,
        setLocalPatientData,
        fetchJobAlerts,
    } = ucrStore;

    const isAdminTime = patient.disposition === ADMIN_TIME;
    const isDoubleUp = isMultiAssigneeJob(patient);
    const isFirstUser = Boolean(!isDoubleUp || (isDoubleUp && focusedUser === 'user1'));

    const { error, data, refetch, networkStatus } = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        notifyOnNetworkStatusChange: true,
        pollInterval: 60000,
        skip: focusedJobId === '',
    });
    const [updateJob, { loading: updatingJob }] = useMutation(UPDATE_JOB);

    const isLoading =
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        networkStatus === NetworkStatus.setVariables;

    const setToFormPatientData = async (data: Patient) => {
        const {
            nhsNumber,
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            addressLine1,
            addressLine2,
            addressLine3,
            town,
            postCode,
            contactNumber,
            additionalContactNumbers,
            pds,
        } = data;

        const patientData = {
            nhsNumber,
            dateOfBirth,
            gender,
            firstName,
            middleName,
            lastName,
            addressLine1,
            addressLine2,
            addressLine3,
            contactNumber,
            additionalContactNumbers: additionalContactNumbers?.filter((str) => str),
            town,
            postCode,
            pds,
            version: patient.version + 1,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().seconds(0).toISOString(),
            id: patient.id,
        };

        clearLocalPatientData();
        clearPDSData();

        await updateJob({ variables: { input: patientData } });
        refetch();
    };

    const updateAssignedStaff = async (hcpId: string | null) => {
        const staff = users.find(({ userId }: { userId: any }) => userId === hcpId);
        const staffName = staff ? `${staff.firstName} ${staff.lastName}` : '';
        let input: APIValuesInterface = {
            id: patient.id,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().toISOString(),
            version: patient.version + 1,
        };
        const statusNeedToBeReset = [JobStatus.CURRENT, JobStatus.ARRIVED, JobStatus.PENDING];
        if (isFirstUser || isAdminTime) {
            input.hcpId = hcpId || null;
            input.hcpName = staffName;
            if (statusNeedToBeReset.includes(patient.jobStatus)) {
                input.jobStatus = hcpId ? JobStatus.ACCEPTED : JobStatus.PENDING;
            }
        } else if (isDoubleUp && !isFirstUser) {
            input.buddyId = hcpId || null;
            input.buddyName = staffName;
            if (statusNeedToBeReset.includes(patient.buddyJobStatus as JobStatus)) {
                input.buddyJobStatus = hcpId ? JobStatus.ACCEPTED : JobStatus.PENDING;
            }
        }
        try {
            await updateJob({
                variables: { input },
            });
            refreshJobs();
        } catch (err) {
            console.error(err);
        }
    };

    const onHcpReassignment = (hcpId: string | null) => {
        if ([JobStatus.CURRENT, JobStatus.ARRIVED].includes(patient?.jobStatus)) {
            openAlert({
                message: `The ${
                    isAdminTime ? 'administrative time' : 'visit'
                } status is ${patient?.jobStatus.toLowerCase()}. Are you sure you want to change it?`,
                isOpen: true,
                onConfirm: () => {
                    updateAssignedStaff(hcpId);
                    closeAlert();
                },
            });
            return;
        }
        updateAssignedStaff(hcpId);
    };

    const loading =
        isLoading || loadingWarnings || loadingPDSData || updatingJob || loadingJobAlerts;

    const isStaffMemberEditable = Boolean(
        patient?.startDateTime &&
            patient?.dateOfVisit &&
            moment().startOf('day').isSameOrBefore(moment(patient.dateOfVisit)) &&
            (isFirstUser
                ? !inactiveStatus.includes(patient?.jobStatus)
                : !inactiveStatus.includes(patient?.buddyJobStatus as JobStatus)),
    );

    useEffect(() => {
        fetchJobAlerts();
    }, [fetchJobAlerts]);

    useEffect(() => {
        if (jobsContext.refreshAssignedJobs) {
            refetch();
        }
    }, [jobsContext.refreshAssignedJobs, refetch]);

    useEffect(() => {
        if (data?.getJob?.nhsNumber && data?.getJob?.nhsNumber !== patient?.nhsNumber) {
            const triggerPDSLookup =
                isFeatureEnabled('pdsLookup') &&
                (activeStates.includes(data.getJob.jobStatus) ||
                    (isMultiAssigneeJob(data.getJob) &&
                        activeStates.includes(data.getJob.buddyJobStatus)));

            if (triggerPDSLookup) {
                setLocalPatientData(data.getJob);
                getPDSData(data.getJob.nhsNumber);
            } else {
                clearLocalPatientData();
                clearPDSData();
            }

            setShowDetailsAmendDialog(triggerPDSLookup);
        }

        setPatient(new Patient(data?.getJob || {}));
    }, [
        clearLocalPatientData,
        clearPDSData,
        data,
        getPDSData,
        isFeatureEnabled,
        patient?.nhsNumber,
        setLocalPatientData,
    ]);

    const isNotDefinedBuddyStatus = !patient.buddyJobStatus ? true : false;
    if (isNotDefinedBuddyStatus && isMultiAssigneeJob(patient) && patient.buddyId) {
        patient.buddyJobStatus = JobStatus.ACCEPTED;
    }

    const currentWarnings: WarningType[] = warnings[focusedJobId] || [];

    const onClose = () => {
        setShowDetailsAmendDialog(false);
        clearLocalPatientData();
        clearPDSData();
    };

    return {
        patient,
        isAdminTime,
        isDoubleUp,
        isFirstUser,
        error,
        data,
        networkStatus,
        isLoading,
        loading,
        updatingJob,
        isStaffMemberEditable,
        showDetailsAmendDialog,
        currentWarnings,
        refetch,
        updateJob,
        onClose,
        setToFormPatientData,
        onHcpReassignment,
        setShowDetailsAmendDialog,
    };
};
