import React, { FC } from 'react';
import { Tabs, Tab, Icon } from '@blueprintjs/core';
import useStores from '../../../hook/useStores';
import { Link } from 'react-router-dom';
import RootStore from '../../../stores/RootStore';
import classNames from 'classnames';
import { getRoutes } from '../controller/getRoutes';
import { getTabId } from './getTabId';
import { getTabTarget } from './getTabTarget';
import { reverseLookupTab } from './getHomepageTab';
import { observer } from 'mobx-react';
import { useUsersForRoleViewModel } from '../../pages/users/useUsersForRoleViewModel';

const Header: FC = () => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
            userStore: { isAdmin, isController, isComplianceOfficer, isSuperuser, logOut },
        },
    } = useStores() as { RootStore: RootStore };

    // Admins can see more users than a normal Controller
    const { users } = useUsersForRoleViewModel();

    const preventDragHandler = (event: React.DragEvent) => event.preventDefault();

    const routes = getRoutes(
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    const availableUsers = users.filter((user: any) => user.available);

    const currentPath = window.location.pathname;
    const selectedTab = reverseLookupTab(
        currentPath,
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    return (
        <header className={classNames('header', 'ucr__header')}>
            <Link to="/">
                <img
                    src={'/images/logos/docabode_logo-white.png'}
                    alt="Doc Abode Logo"
                    className="header__logo"
                />
            </Link>
            <Tabs id="NavigationTabs" selectedTabId={selectedTab} className="header__tabs" large>
                {routes.map((route) => {
                    return (
                        <Tab
                            id={getTabId(route)}
                            key={getTabId(route)}
                            onDragStart={preventDragHandler}
                        >
                            <Link to={getTabTarget(route)}>
                                {route.tabIcon && (
                                    <Icon icon={route.tabIcon} className="header__tab-icon" />
                                )}
                                {route.tabLabel}
                                {getTabId(route) === 'users' && (
                                    <>
                                        <span className="nav__users-available">
                                            {' '}
                                            {availableUsers.length}
                                        </span>
                                        /<span className="nav__users-total">{users.length}</span>
                                    </>
                                )}
                            </Link>
                        </Tab>
                    );
                })}
                <Tab id="logout" onDragStart={preventDragHandler}>
                    <Link to="/logout" onClick={() => logOut()}>
                        <Icon icon="log-out" className="header__tab-icon" />
                        Log out
                    </Link>
                </Tab>
            </Tabs>
        </header>
    );
};

export default observer(Header);
