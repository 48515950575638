import { baseUrl, retryable } from './baseApi';
export const fetchSchedules = (
    authToken: string,
    query: { [key: string]: any },
    noCache?: boolean,
): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules?${new URLSearchParams(query)}`, {
            headers: {
                Authorization: authToken,
                'Cache-Control': noCache ? 'no-cache' : 'max-age=120',
            },
        }),
    );

export const setSchedule = (authToken: string, data: Object): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
            body: JSON.stringify(data),
        }),
    );

export const getFileUploadParams = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules/upload`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const uploadFile = (
    key: string,
    file: string | Blob,
    uploadParams: { [key: string]: any },
): Promise<any> => {
    const formData = new FormData();

    Object.keys(uploadParams.fields).forEach((param) => {
        formData.append(param, uploadParams.fields[param]);
    });

    formData.append('key', key);
    formData.append('file', file);

    return fetch(uploadParams.url, {
        method: 'POST',
        body: formData,
    });
};

export const confirmSchedulesUpload = (
    authToken: string | null,
    uploadId: string | null,
    data: { [key: string]: any },
): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules/upload/${uploadId}/confirm`, {
            method: 'POST',
            headers: {
                Authorization: authToken || '',
            },
            body: JSON.stringify(data),
        }),
    );

export const previewSchedulesUpload = (
    authToken: string,
    uploadId: string,
    data: { [key: string]: any },
): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules/upload/${uploadId}/preview`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
            body: JSON.stringify(data),
        }),
    );

export const exportSchedulesFetch = (authToken: string, date: any): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules/export`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
            body: JSON.stringify({
                date,
            }),
        }),
    );

export const validateSchedule = (authToken: string, data: { [key: string]: any }): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/schedules/validation`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
            body: JSON.stringify(data),
        }),
    );
