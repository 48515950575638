import _ from 'lodash';
import { formatAPIMultiSelect, formatDateTime, formatDateOfBirth } from '../../helpers/formatData';
import { formFields } from './formFields';

export const emptyErrorByType = (fieldValue, fieldType) => {
    const emptyFieldErrorMessage = 'This field is required.';
    switch (fieldType) {
        case 'number':
            return !_.isNumber(fieldValue) ? emptyFieldErrorMessage : '';
        case 'date':
            return !_.isDate(fieldValue) ? emptyFieldErrorMessage : '';
        case 'object':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        case 'string':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        case 'array':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        default:
            return [];
    }
};

export const regexFieldValidation = (value, regex) => {
    if (regex) {
        return regex.test(value);
    }
    return true;
};

export const getAPIReadyJobData = (state, { lat, lng }, organisation, actionType) => {
    let scheduleOptions = undefined;

    let normalizedData = {
        systmOneRef: state.fields.systmOneRef === '' ? null : state.fields.systmOneRef,
        nhsNumber: state.fields.nhsNumber === '' ? null : state.fields.nhsNumber,
        jobType: state.fields.jobType.value ? state.fields.jobType.value : '',
        disposition: state.fields.disposition.value ? state.fields.disposition.value : null,
        blockSize: state.fields.blockSize.label !== '' ? state.fields.blockSize.label : null,
        firstName: state.fields.firstName !== '' ? state.fields.firstName : null,
        lastName: state.fields.lastName !== '' ? state.fields.lastName : null,
        gender: state.fields.gender.value ? state.fields.gender.value : null,
        addressLine1: state.fields.addressLine1 !== '' ? state.fields.addressLine1 : null,
        addressLine2: state.fields.addressLine2 !== '' ? state.fields.addressLine2 : null,
        town: state.fields.town !== '' ? state.fields.town : null,
        postCode: state.fields.postCode !== '' ? state.fields.postCode : null,
        hcpType: state.fields.hcpType.length ? formatAPIMultiSelect(state.fields.hcpType) : [],
        nominatedHcps: state.fields.nominatedHcps.length
            ? formatAPIMultiSelect(state.fields.nominatedHcps)
            : [],
        languagesSpoken: state.fields.languagesSpoken.length
            ? formatAPIMultiSelect(state.fields.languagesSpoken)
            : [],
        speciality: state.fields.speciality.length
            ? formatAPIMultiSelect(state.fields.speciality)
            : [],
        flags: state.fields.flags.length ? formatAPIMultiSelect(state.fields.flags) : [],
        dateOfBirth:
            state.fields.dateOfBirth !== null ? formatDateOfBirth(state.fields.dateOfBirth) : null,
        age:
            state.fields.age !== '' && state.fields.age !== null
                ? parseInt(state.fields.age, 0)
                : null,
        contactNumber: state.fields.contactNumber !== '' ? state.fields.contactNumber : null,
        priceInPence: state.fields.price !== null ? state.fields.price * 100 : null,
        hidePriceInApp: state.fields.hidePriceInApp !== null ? state.fields.hidePriceInApp : false,
        expiryDateTime:
            state.fields.expiryDate !== null && state.fields.expiryTime !== null
                ? formatDateTime(state.fields.expiryDate, state.fields.expiryTime)
                : '',
        notes: state.fields.notes !== '' ? state.fields.notes : null,
        practice: state.fields.practice.value ? state.fields.practice.value : null,
        latitude: lat ? lat : null,
        longitude: lng ? lng : null,
        version: state.fields.version,
        organisation,
        scheduleOptions,
    };

    // Remove fields not relevant to job type
    formFields.forEach((field) => {
        const { jobType } = normalizedData;
        const { visible, name } = field;
        if (visible !== true && Array.isArray(visible) && !visible.includes(jobType)) {
            if (name === 'endDate') {
                delete normalizedData.endDateTime;
            } else if (name === 'startDate') {
                delete normalizedData.startDateTime;
            } else {
                delete normalizedData[name];
            }
        }
    });

    // Remove empty optional fields
    _.forIn(state.validation, (fieldValidation, field) => {
        if (
            normalizedData[field] === null &&
            (fieldValidation.required === false ||
                fieldValidation.required !== state.fields.jobType.value)
        ) {
            delete normalizedData[field];
        }
    });

    return normalizedData;
};
