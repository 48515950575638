import { sortBy } from 'lodash';
import getHcpName from '../../../helpers/ucr/getHcpName';

import useStores from '../../../hook/useStores';
import { Callout } from '../components/Callout';
import { IconWarning } from '../../../helpers/ucr/icons';
import { WarningType } from '../../../interfaces/ucr';

type WarningsProps = {
    warnings: WarningType[];
    stepWarnings?: Array<string>;
    spacerTop?: boolean;
};

const Warnings = ({ warnings = [], stepWarnings = [], spacerTop = true }: WarningsProps) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores();

    return warnings?.length || stepWarnings.length || Object.keys(warnings).length ? (
        <Callout intent="warning" Icon={IconWarning} spacerTop={spacerTop}>
            {sortBy(warnings, ['category']).map(({ message, category, data }: WarningType, i) => (
                <p className="warning-block" key={i}>
                    {category === 'PATIENT_SCHEDULING_CONFLICT'
                        ? `The patient already has a visit scheduled within this time period`
                        : category === 'WARNING_GEOLOCATION_MISSING'
                        ? message
                        : category === 'HCP_SCHEDULING_CONFLICT'
                        ? `Scheduling conflict identified for: ${getHcpName(users, data?.hcpId)}`
                        : category === 'HCP_AVAILABILITY_CONFLICT'
                        ? `Planned time of the visit is out of ${getHcpName(
                              users,
                              data?.hcpId,
                          )} availability hours!`
                        : null}
                </p>
            ))}
            {stepWarnings?.length
                ? stepWarnings.map((warning, i) => <p key={i}>{warning}</p>)
                : null}
        </Callout>
    ) : null;
};

export default Warnings;
