import { gql } from '@apollo/client';

export const GET_PERSON_BY_COGNITO_ID = gql`
    query GetPersonByCognitoId($cognitoId: String!) {
        getPersonByCognitoId(cognitoId: $cognitoId) {
            id
            firstName
            lastName
        }
    }
`;
