import { FC } from 'react';
import { Formik } from 'formik';
import useStores from '../../../../../hook/useStores';
import AbortDoubleVisitForm from './AbortDoubleVisitForm';

import { useAbortVisitViewModel } from './useAbortVisitViewModel';

interface IProps {
    onClose: () => void;
}

const AbortVisit: FC<IProps> = ({ onClose }) => {
    const { isDisabled, loading, reasonsForAbort, onSubmit } = useAbortVisitViewModel();

    const {
        RootStore: {
            ucrStore: { focusedUser, abortVisit },
        },
    } = useStores() as { RootStore: any };

    if (!abortVisit) return <></>;

    return (
        <Formik
            initialValues={{
                cancelationOption: !isDisabled && (focusedUser || ''),
                controllerAbortedNotes: '',
                controllerAbortedReason: reasonsForAbort,
            }}
            onSubmit={onSubmit}
        >
            <AbortDoubleVisitForm visit={abortVisit} loading={loading} onClose={onClose} />
        </Formik>
    );
};

export default AbortVisit;
