import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import moment from 'moment';
import {
    Hub,
    Itinerary as ItineraryType,
    RoutePatientInput,
    Vaccination,
} from '@doc-abode/data-models';
import { Button, Callout } from '@blueprintjs/core';

import useStores from '../../../../../../hook/useStores';
import { vaccinationRouteStatusAllowsRecalculation } from '../../../../../../models/PatientRemoveFromRoute';
import RootStore from '../../../../../../stores/RootStore';
import {
    checkUpdatedRoute,
    confirmUpdatedRoute,
    updateRoute,
    removeVaccinationFromRouteNoRecalculation,
} from '../../../../../../api/routesApi';
import { getJob } from '../../../../../../api/jobsApi';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatPatientForRoute, getWarnings } from '../../../utils';
import { VaccinationRoute, VaccinationWithWarnings } from '../../../types';
import Modal from '../../../../../modules/modal/Modal';
import Loader from '../../../../../modules/helpers/Loader';
import { NewItinerarySection } from '../NewItinerarySection';
import { PickupRecalculationOptionsDialog } from './PickupRecalculationOptionsDialog';
import { UpdateRouteAddPatients } from './UpdateRouteAddPatients';
import {
    getFilteredRoutesBetterFunctionNameToFollow,
    vaccinationRouteHasPickup,
} from './updateRouteHelpers';

interface Props {
    patientsToAdd: Vaccination[];
    onClose: () => void;
    deselect: (id: string) => void;
    setVaccinations: () => void;
    allPatients: Vaccination[];
    addPatients: boolean;
    recalculateRoute: boolean;
    onSort: () => void;
}

export type RouteUpdateCheckResponse = ItineraryType & {
    callbackInSeconds: number;
};

interface IGetTitle {
    recalculateRoute: boolean;
    addPatients: boolean;
}

/**
 * workout what the title should be.
 */
export function getTitle({ recalculateRoute, addPatients }: IGetTitle): string {
    let title;
    switch (true) {
        case recalculateRoute:
            title = 'Recalculate route';
            break;
        case addPatients:
            title = 'Add patients to a route';
            break;
        default:
            title = 'Remove patient from a route';
    }
    return title;
}
/**
 * helper function
 * todo better name as newItinerary is not an Itinerary its an RouteUpdateCheckResponse
 * so what is happening when newItinerary is not null
 */
export function showFooterIfWeHaveNewItineraryAndWeAreNotConfirmingRoute(
    newItinerary: RouteUpdateCheckResponse | null | undefined,
    confirmingRoute: boolean,
): boolean {
    if (newItinerary && !confirmingRoute) {
        return true;
    }
    return false;
}
interface IMakeHandleClosePickupRecalculationOptionsDialog {
    addPatients: boolean;
    history: History;
    selectedRoute: VaccinationRoute | null;
    recalculateRoute: boolean;
    setShowPickupRecalculationOptionsDialog: (value: boolean) => void;
}

export function makeHandleClosePickupRecalculationOptionsDialog({
    addPatients,
    history,
    selectedRoute,
    recalculateRoute,
    setShowPickupRecalculationOptionsDialog,
}: IMakeHandleClosePickupRecalculationOptionsDialog) {
    return () => {
        setShowPickupRecalculationOptionsDialog(false);
        if (recalculateRoute) {
            if (selectedRoute) {
                // "from" is passed in state for use in RouteDetails
                // Recalculation sets editing to true in RouteDetails.
                // This is the only place from value is set, and RouteDetails is the only place its used.
                history.push(`/vaccinations/routes/${selectedRoute.id}`, { from: 'Recalculation' });
            } else {
                history.goBack();
            }
        }
        // if we are removing from the route.
        if (!addPatients && !recalculateRoute) {
            history.goBack();
        }
    };
}
export const ConfirmUpdateRouteFooter = ({ onConfirm }: { onConfirm: () => void }) => (
    <Button intent="success" large onClick={onConfirm} icon="tick">
        Accept changes
    </Button>
);

interface IPatientToRemoveLinkProps {
    removedByUser: Vaccination[];
}

/**
 * PatientToRemoveLink was extracted from the UpdateRoute component to facilitate testing.
 * I am not sure removedByUser as  Vaccination[] is the correct typing.
 * Looking at the code it should probably be removedByUser: Vaccination
 */
export const PatientToRemoveLink = ({ removedByUser }: IPatientToRemoveLinkProps) => {
    const [{ id, firstName, lastName, nhsNumber }] = removedByUser;
    return (
        <Link to={`/vaccinations/patients/${id}`}>
            {firstName} {lastName} ({nhsNumber})
        </Link>
    );
};

/**
 * When this component first renders we are in a state where we are getting data from the server so the spinner shows.
 * This is part of the UI that initiates calls to the back end for route related stuff
 * Is this just VaccineRoute?
 * Will it always be VaccineRoute? or is it correct to leave it generic even though its in the Vaccinations react-router-dom section
 * This seems to handled lots of route related stuff, edit existing route  multiple ways, but not create a new route
 * (that's done in CreateRoutes)
 *
 * we can:
 *   /recalculate
 *   /withdraw-from-existing
 *   /add-to-existing
 *
 *   the path is checked in the parent component (Routes.js  ~ line 1017) which decides if this component is rendered
 *   what does this component do if its /withdraw-from-existing
 *
 *   if seems to be that if it's not a recalculate and it's not an addPatients then it must be a /withdraw-from-existing
 *   see useEffect from ~line 268 onwards
 *   which ultimately triggers a set of actions that calls:
 *   routesApi.confirmUpdatedRoute
 *
 *   Looks like we check if this component is being used for
 *   recalculation or adding a patient , and the component behaves accordingly,  if it's not recalculation or adding a patient then the component
 *   is for withdraw patient.  this seems a little woolly, and we have to do this check in several places so its error-prone
 *
 * @constructor
 */
export const UpdateRoute: FC<Props> = ({
    patientsToAdd,
    onClose,
    deselect,
    setVaccinations,
    allPatients,
    addPatients,
    onSort,
    recalculateRoute,
}) => {
    const {
        RootStore: {
            routesStore: { allJobs: routes, setJobs, editJob },
            userStore: { getAuthToken },
            configStore: {
                hubs,
                clientKeys,
                vaccinationDetails,
                vaccinationDuration,
                doseInterval,
            },
            lovsStore: { hcpType },
        },
    } = useStores() as { RootStore: RootStore };
    const { state } = useLocation() as {
        state: {
            routeType: string;
            hub: Hub;
            selectedStartTime: string;
            userId: string;
            patient: Vaccination;
            route: VaccinationRoute;
        };
    };
    const [selectedRoute, setSelectedRoute] = useState<VaccinationRoute>(state.route);
    const [newItinerary, setNewItinerary] = useState<RouteUpdateCheckResponse | null>(null);
    const [multipleHubWarning, setMultipleHubWarning] = useState(false);
    const [removeRequestMade, setRemoveRequestMade] = useState(false);
    const [didError, setDidError] = useState<{ message: string; details: string[] } | null>(null);
    const [unscheduledByCapacity, setUnscheduledByCapacity] = useState<Vaccination[]>([]);
    const [unscheduledByOptimisation, setUnscheduledByOptimisation] = useState<Vaccination[]>([]);
    const [removedByOptimisation, setRemovedByOptimisation] = useState<Vaccination[]>([]);
    // todo not sure if removedByUser: Vaccination[] looking at the code it should probably be removedByUser: Vaccination
    const [removedByUser, setRemovedByUser] = useState<Vaccination[]>([]);
    const [emptyItinerary, setEmptyItinerary] = useState(false);
    const [cannotRecalculate, setCannotRecalculate] = useState(false);
    const [mostRecentPreviousDose, setMostRecentPreviousDose] = useState('');
    // if a route is to be recalculated and the route has a pickup:  show the recalculation options modal (PickupRecalculationOptionsDialog)
    const [
        showPickupRecalculationOptionsDialog,
        setShowPickupRecalculationOptionsDialog,
    ] = useState(false);
    const [newHub, setNewHub] = useState<Hub | null>(null);
    const [patientsWithWarnings, setPatientsWithWarnings] = useState<VaccinationWithWarnings[]>([]);
    const [confirmingRoute, setConfirmingRoute] = useState(false);
    // LOADING SPINNER AND MESSAGE
    const [loaderSpinner, setLoaderSpinner] = useState<{ message: string; show: boolean }>({
        message: 'Getting data ...',
        show: true,
    });
    const [
        showVaccinationRemovedSuccessfully,
        setShowVaccinationRemovedSuccessfully,
    ] = useState<boolean>(false);

    const history = useHistory();
    // why do we need useCallback?
    const resetPatient = useCallback(
        async (patient: Vaccination | null | undefined) => {
            if (!patient) {
                return;
            }

            try {
                await editJob({
                    ...patient,
                    jobStatus: 'PENDING',
                    hcpId: undefined,
                    hcpSub: undefined,
                    hcpName: undefined,
                    acceptedDateTime: undefined,
                    madeCurrentDateTime: undefined,
                    itineraryId: undefined,
                });
            } catch (err: any) {
                console.error(err.message);
                setDidError(err.message);
            }
        },
        [editJob],
    );

    const onRemoveWarningPatients = () => {
        patientsWithWarnings.forEach(({ id }) => deselect(id));
    };
    const onRequestUpdatedRoute = useCallback(
        /**
         *  calls the api via src/api/routesApi.js::updateRoute
         */
        async (
            route: VaccinationRoute,
            body: { [key: string]: any },
            patient?: Vaccination | null,
        ) => {
            setLoaderSpinner({ message: 'Getting data ...', show: true });
            try {
                const authToken = await getAuthToken();
                // todo deal with route.itineraryId being undefined. just done a lazy default to empty string for now
                const itineraryId = route.itineraryId || '';
                // Send the update information
                const response = await updateRoute(authToken, itineraryId, body);
                const { requestId } = response;
                let timeout = response.callbackInSeconds;

                const queryRoutes: () => void = async () => {
                    const routeUpdateCheckResponse: RouteUpdateCheckResponse = await new Promise(
                        (resolve) =>
                            setTimeout(async () => {
                                try {
                                    const response = await checkUpdatedRoute(
                                        authToken,
                                        itineraryId,
                                        requestId,
                                        body.selectedStartTime,
                                    );
                                    resolve(response);
                                } catch (err: any) {
                                    let error;
                                    try {
                                        error = JSON.parse(err);
                                    } catch (err) {}

                                    if (
                                        error?.message ===
                                        'Could not generate an itinerary with the given criteria'
                                    ) {
                                        if (!recalculateRoute) {
                                            await resetPatient(patient);
                                            await editJob({ ...route, itinerary: undefined });
                                        }
                                        setCannotRecalculate(true);
                                    } else {
                                        console.error(err.message);
                                        setDidError(error);
                                    }
                                }
                            }, timeout * 1000),
                    );
                    // looks like we recursively check for the updated route.
                    // If it's not been provided after the first x seconds we try again
                    // if routeUpdateCheckResponse.callbackInSeconds exists then that means try again
                    if (routeUpdateCheckResponse.callbackInSeconds) {
                        timeout = routeUpdateCheckResponse.callbackInSeconds;
                        // try again to get the route now it's been updated
                        return queryRoutes();
                    }
                    // We got the result we wanted, the updated route,  now do something with it.
                    if (
                        addPatients ||
                        recalculateRoute ||
                        // We have removed a patient and the route should be recalculated.
                        // Removing a patient is currently inferred as we do not explicitly say we are removing a patient (via passed props),
                        // its just that addPatients and recalculateRoute are both false.
                        // Should that change, it may be better to use
                        // PatientRemoveFromRoute.vaccinationRouteShouldBeRecalculatedWhenRemovingPatient
                        vaccinationRouteStatusAllowsRecalculation({
                            vaccinationRoute: route,
                        })
                    ) {
                        const unscheduledByCapacity: Vaccination[] = [];
                        const unscheduledByOptimisation: Vaccination[] = [];
                        const removedByOptimisation: Vaccination[] = [];
                        const removedByUser: Vaccination[] = [];
                        if (
                            // VSU-2078 bit of a hack, I think that this makes sense that we removedByUser the patient being removed, and that it's done here
                            // it looks like the api does not need to know about it at this stage.
                            vaccinationRouteStatusAllowsRecalculation({
                                vaccinationRoute: route,
                            }) &&
                            patient &&
                            body?.removePatients?.includes(patient.id)
                        ) {
                            removedByUser.push(patient);
                        }
                        await Promise.all(
                            routeUpdateCheckResponse.unscheduledItems.map(
                                async ({ id, reason }) => {
                                    let patient = allPatients.find((patient) => patient.id === id);
                                    if (!patient) {
                                        const authToken = await getAuthToken();
                                        const response: any = await getJob(authToken, id);
                                        patient = response?.Items[0];
                                    }

                                    if (patient) {
                                        if (reason === 'CAPACITY') {
                                            unscheduledByCapacity.push(patient);
                                        } else if (reason === 'OPTIMISATION') {
                                            if (
                                                routeUpdateCheckResponse.removedPatients.some(
                                                    ({ id }) => id === patient?.id,
                                                ) &&
                                                !recalculateRoute
                                            ) {
                                                removedByOptimisation.push(patient);
                                            } else {
                                                unscheduledByOptimisation.push(patient);
                                            }
                                        } else if (reason === 'HCP_ABORTED') {
                                            removedByUser.push(patient);
                                        }
                                    }
                                },
                            ),
                        );
                        setNewItinerary(routeUpdateCheckResponse);
                        setLoaderSpinner({ message: '', show: false });
                        setUnscheduledByCapacity(unscheduledByCapacity);
                        setUnscheduledByOptimisation(unscheduledByOptimisation);
                        setRemovedByOptimisation(removedByOptimisation);
                        setRemovedByUser(removedByUser);
                    }
                };
                // update and then get the updated recalculated route
                queryRoutes();
            } catch (err: any) {
                let error;
                console.log('err', err);
                try {
                    error = JSON.parse(err.message);
                } catch (err) {
                    /* TODO?  something with the error?*/
                }

                if (
                    error?.message === 'Requested operation would result in itinerary with no items'
                ) {
                    await resetPatient(patient);
                    await editJob({ ...route, itinerary: undefined });
                    setEmptyItinerary(true);
                    setDidError(error);
                } else {
                    console.error(err.message);
                    setDidError(error);
                }
                setLoaderSpinner({ message: '', show: false });
            }
        },
        [getAuthToken, addPatients, allPatients, resetPatient, editJob, recalculateRoute],
    );

    useEffect(() => {
        if (recalculateRoute) {
            const { route, selectedStartTime, hub, userId } = state;

            let routeParameters = { ...route } as VaccinationRoute;

            if (!routeParameters.hcpId) {
                routeParameters.hcpId = undefined;
            }

            setSelectedRoute(routeParameters);
            const requestBody = {
                selectedStartTime,
                userId,
                ...(hub && { hub: { address: hub.address } }),
            };
            if (hub) {
                setNewHub(hub);
            }
            if (confirmingRoute) {
                return;
            }
            if (vaccinationRouteHasPickup({ vaccinationRoute: routeParameters })) {
                // route has at least one pick up, and we are explicitly (passed in props) recalculating
                // so we need to show the recalculation dialog
                setShowPickupRecalculationOptionsDialog(true);
            } else {
                // No pickups on the route
                onRequestUpdatedRoute(routeParameters, requestBody, null);
            }
            return;
        }
        // is not add patients and not removeRequestMade and not recalculateRoute (returns above if its recalculateRoute)
        // We will request and update of the route via onRequestUpdatedRoute  (this is a recalculation)
        // looks like removeRequestMade is set to true once this section of code has been executed to prevent
        // removing the same patient
        // this bit of code is executed when you are removing a patient,  but it implied by simply not being
        // addPatients or recalculate as passed in the components props
        // looks like we need to check here if we do the recalculation of the route based on the VaccinationRoute.jobStatus
        // if we are removing a patient.
        // added && !recalculateRoute so we know its part of the required conditions for this bit of code to execute (for refactoring purposes
        // the bock above that triggers for recalculateRoute ===  true may end up being moved
        if (!addPatients && !removeRequestMade && !recalculateRoute) {
            // REMOVING A PATIENT
            const { patient, route } = state;
            setRemovedByUser([patient]);
            setSelectedRoute(route);
            setRemoveRequestMade(true);

            if (vaccinationRouteStatusAllowsRecalculation({ vaccinationRoute: route })) {
                // VSU-2078 if recalculating route and pickups on route show
                // showPickupDetailsDialog
                // handleClosePickupDetailsDialog then needs to call onRequestUpdatedRoute
                // this bit of code is similar to the recalculateRoute section
                // be we are using state.route  rather than state.routeDetails <- try to find out what the difference is.
                if (vaccinationRouteHasPickup({ vaccinationRoute: route })) {
                    // route has at least one pick up, show the recalculation dialog for further user input.
                    setShowPickupRecalculationOptionsDialog(true);
                } else {
                    // start the removal process on the api.
                    onRequestUpdatedRoute(route, { removePatients: [patient.id] }, patient);
                }
            } else {
                // VSU-2078
                // Withdraw without a recalculation
                const handleRemoveVaccinationNoRecalculation = async () => {
                    setLoaderSpinner({ message: 'Removing patient from route...', show: true });
                    const authToken = await getAuthToken();
                    await removeVaccinationFromRouteNoRecalculation(authToken, {
                        vaccinationId: patient.id,
                        vaccinationRouteId: route.id,
                    });
                };

                handleRemoveVaccinationNoRecalculation()
                    .catch((error) => {
                        // todo how do we handle this sort of error.
                        console.error(error);
                    })
                    .then(() => {
                        setLoaderSpinner({ message: '', show: false });
                        setShowVaccinationRemovedSuccessfully(true);
                    });
            }
            return;
        } else if (!addPatients) {
            return;
        }

        if (addPatients) {
            let multipleHubWarning = false;

            patientsToAdd.forEach((job) => {
                const { hubId } = job;
                const hub = hubs.find((hub) => hub.id === hubId);

                const { compatibleWith = [] } = hub || {};
                const otherHubIds = patientsToAdd
                    .filter((otherJob) => otherJob.id !== job.id)
                    .map((otherJob) => otherJob.hubId);

                if (
                    otherHubIds.some(
                        (hubId) => hubId !== hub?.id && !compatibleWith.includes(hubId),
                    )
                ) {
                    multipleHubWarning = true;
                }
            });

            setMultipleHubWarning(multipleHubWarning);
        }
    }, [
        hubs,
        patientsToAdd,
        addPatients,
        state,
        onRequestUpdatedRoute,
        removeRequestMade,
        recalculateRoute,
        confirmingRoute,
        getAuthToken,
    ]);
    // WARNINGS. Patients being added may have a warning.
    useEffect(() => {
        const fetchWarnings = async () => {
            const warnings = await getWarnings(
                patientsToAdd,
                null,
                doseInterval,
                vaccinationDetails,
            );
            setPatientsWithWarnings(warnings);
            setLoaderSpinner({ message: '', show: false });
        };

        if (patientsToAdd?.length > 0) {
            fetchWarnings();
        }
    }, [patientsToAdd, vaccinationDetails, doseInterval]);

    // I think this gets the date of the most recent previous dose from the group of patients that are to be added.
    // We then use the date with the minDaysSincePreviousDose to filter the routes
    useEffect(() => {
        let mostRecentPreviousDose = '';
        patientsToAdd.forEach(({ dateOfPreviousDose }) => {
            if (!dateOfPreviousDose) {
                return;
            }

            if (
                mostRecentPreviousDose === '' ||
                moment(dateOfPreviousDose).isAfter(moment(mostRecentPreviousDose))
            ) {
                mostRecentPreviousDose = dateOfPreviousDose;
            }
        });
        setMostRecentPreviousDose(mostRecentPreviousDose);
    }, [patientsToAdd]);

    const routeType = state?.routeType;
    const filteredRoutes = getFilteredRoutesBetterFunctionNameToFollow({
        hubs,
        vaccinations: patientsToAdd,
        vaccinationRoutes: routes,
        vaccinationRouteType: routeType,
        mostRecentPreviousDose,
    });

    const removedPatients = [
        ...removedByOptimisation,
        ...removedByUser,
        ...unscheduledByOptimisation,
    ];

    const handleOnClickUpdateRouteAddPatients = async (id: string) => {
        // I'm not sure that the setShowLoaderSpinner state change matter as its gets
        // immediately set to false. (nb this flow is from the original UpdateRoute code)
        setLoaderSpinner({ message: 'Fetching route details...', show: true });
        const authToken = await getAuthToken();
        const response: any = await getJob(authToken, id);

        const route = response?.Items[0] as VaccinationRoute;
        setSelectedRoute(route);
        setLoaderSpinner({ message: '', show: false });

        // If there were no pickups on the route before then we don't need to show the modal recalculation options
        if (!vaccinationRouteHasPickup({ vaccinationRoute: route })) {
            let addPatients: RoutePatientInput[] = [];
            if (addPatients) {
                addPatients = patientsToAdd.reduce((patients, job) => {
                    const formattedPatient = formatPatientForRoute({
                        patient: job,
                        selectedDate: route?.itinerary.route.startTime,
                        vaccinationDuration,
                        vaccinationDetails,
                        routeType,
                        jobs: allPatients,
                    });

                    if (formattedPatient) {
                        patients.push(formattedPatient);
                    }

                    return patients;
                }, [] as RoutePatientInput[]);
            }
            await onRequestUpdatedRoute(route, { addPatients });
        } else {
            setShowPickupRecalculationOptionsDialog(true);
        }
    };

    const oldRoute = selectedRoute?.itinerary.route;
    const newRoute = newItinerary?.route;

    const handleConfirmUpdateRoute = async () => {
        setConfirmingRoute(true);
        setLoaderSpinner({ message: 'Confirming route...', show: true });
        setShowPickupRecalculationOptionsDialog(false);

        let addedPatients;
        if (addPatients) {
            const existingPatients = selectedRoute?.itinerary.instructions
                .map((instruction) => instruction.itineraryItem?.name)
                .filter((patient) => patient);

            addedPatients = newItinerary?.instructions
                .filter((instruction) => instruction.instructionType !== 'Pickup')
                .map((instruction) => instruction.itineraryItem?.name)
                .filter((patient) => patient && !existingPatients?.includes(patient));
        }

        try {
            await editJob({ ...selectedRoute, itinerary: undefined });
            const authToken = await getAuthToken();
            await confirmUpdatedRoute(authToken, newItinerary?.itineraryId, {
                route: selectedRoute?.id,
                addedPatients,
                removedPatients: removedPatients.map(({ id }) => id),
                hcpTypes: hcpType.map(({ value }) => value),
                hubId: newHub?.id,
            });
            await setJobs();
            patientsToAdd?.forEach(({ id }) => deselect(id));
            setVaccinations();
            history.push(`/vaccinations/routes/${selectedRoute?.id}`, { reload: true });
        } catch (err) {
            console.error(err);
            setLoaderSpinner({ message: '', show: false });
            setConfirmingRoute(false);
        }
    };

    // passed every time we display PickupRecalculationOptionsDialog
    // triggered onClose PickupRecalculationOptionsDialog which is a "we don't do anything" action
    // PickupRecalculationOptionsDialogPickupRecalculationOptionsDialog::onConfirm is the function that "does something"

    // triggers a state change, so we get a re-render here, which is how any changes that have been made in PickupRecalculationOptionsDialog
    // will get processed.
    // This sends us to Routes with the route id which would then
    // send us to RouteDetails
    const handleClosePickupRecalculationOptionsDialog = makeHandleClosePickupRecalculationOptionsDialog(
        {
            addPatients,
            history,
            selectedRoute,
            recalculateRoute,
            setShowPickupRecalculationOptionsDialog,
        },
    );

    const title = getTitle({
        recalculateRoute,
        addPatients,
    });
    const showFooter = showFooterIfWeHaveNewItineraryAndWeAreNotConfirmingRoute(
        newItinerary,
        confirmingRoute,
    );
    let patientsToAddPickupRecalculationOptionsDialog: Vaccination[] = [];
    if (addPatients) {
        patientsToAddPickupRecalculationOptionsDialog = patientsToAdd;
    }
    const { show: loaderSpinnerShow, message: loaderSpinnerMessage } = loaderSpinner;

    return (
        <Modal
            title={title}
            onClose={onClose}
            footer={
                <ConditionalDisplay show={showFooter}>
                    <ConfirmUpdateRouteFooter onConfirm={handleConfirmUpdateRoute} />
                </ConditionalDisplay>
            }
            shadow
        >
            <ConditionalDisplay show={loaderSpinnerShow}>
                {/* LOADING SPINNER AND MESSAGE */}
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">{loaderSpinnerMessage}</p>
                </div>
            </ConditionalDisplay>
            <ConditionalDisplay show={showVaccinationRemovedSuccessfully}>
                <Callout intent="success" icon="tick-circle" className="create-routes__callout">
                    Patient successfully removed from route.
                </Callout>
            </ConditionalDisplay>
            <ConditionalDisplay show={!loaderSpinnerShow && !showVaccinationRemovedSuccessfully}>
                {/* a whole mass of nested ternaries.  Not ideal -  nb the show logic for this conditional display
                is there because the proceeding 2 ConditionalDisplay used to be part of this ternary fest.*/}
                {multipleHubWarning ? (
                    <>
                        <Callout intent="danger" className="create-routes__callout">
                            The selected patients belong multiple hubs that cannot be combined into
                            a single route. Please refine your selection and try again.
                        </Callout>
                        <div>
                            <Button
                                icon="circle-arrow-left"
                                onClick={() => history.push('/vaccinations/patients')}
                                text="Go back"
                            />
                        </div>
                    </>
                ) : emptyItinerary && removedPatients?.length > 0 ? (
                    <Callout intent="warning" className="create-routes__callout">
                        The route could not be recalculated because it would result in an itinerary
                        with no patients. However,{' '}
                        <PatientToRemoveLink removedByUser={removedByUser} /> has been reset to an
                        'Unassigned' status so can now be withdrawn or added to a new route as
                        required.
                    </Callout>
                ) : cannotRecalculate && removedPatients?.length > 0 ? (
                    <Callout intent="warning" className="create-routes__callout">
                        The route could not be recalculated in its current state.{' '}
                        {!addPatients && !recalculateRoute && (
                            <>
                                However, <PatientToRemoveLink removedByUser={removedByUser} /> has
                                been reset to an 'Unassigned' status so can now be withdrawn or
                                added to a new route as required.
                            </>
                        )}
                    </Callout>
                ) : newItinerary ? (
                    <NewItinerarySection
                        newItinerary={newItinerary}
                        oldEndTime={oldRoute!.endTime}
                        oldTotalTravelDistance={oldRoute!.totalTravelDistance}
                        oldTotalTravelTime={oldRoute!.totalTravelTime}
                        newEndTime={newRoute!.endTime}
                        newTotalTravelDistance={newRoute!.totalTravelDistance}
                        newTotalTravelTime={newRoute!.totalTravelTime}
                        removedByUser={removedByUser}
                        removedByOptimisation={removedByOptimisation}
                        unscheduledByCapacity={unscheduledByCapacity}
                        unscheduledByOptimisation={unscheduledByOptimisation}
                        patientsToAdd={patientsToAdd}
                        addPatients={addPatients}
                        getAuthToken={getAuthToken}
                        apiKey={clientKeys.googleMaps}
                    />
                ) : addPatients ? (
                    <UpdateRouteAddPatients
                        didError={didError}
                        filteredRoutes={filteredRoutes}
                        hubs={hubs}
                        onClick={handleOnClickUpdateRouteAddPatients}
                        onRemoveWarningPatients={onRemoveWarningPatients}
                        onSort={onSort}
                        patientsToAdd={patientsToAdd}
                        patientsWithWarnings={patientsWithWarnings}
                    />
                ) : recalculateRoute ? (
                    <>
                        {didError && (
                            <Callout intent="danger" className="create-routes__callout">
                                Route generation was unsuccessful. Reported error:{' '}
                                {didError.message}
                                {didError.details?.map((detail) => (
                                    <div key={detail}>{detail}</div>
                                ))}
                            </Callout>
                        )}
                    </>
                ) : null}
            </ConditionalDisplay>
            <PickupRecalculationOptionsDialog
                patientsToAdd={patientsToAddPickupRecalculationOptionsDialog}
                vaccinationToRemove={state?.patient}
                route={selectedRoute}
                selectedStartTime={state?.selectedStartTime}
                hub={state?.hub}
                isOpen={showPickupRecalculationOptionsDialog}
                handleClose={handleClosePickupRecalculationOptionsDialog}
                setIsOpen={setShowPickupRecalculationOptionsDialog}
                onRequestUpdatedRoute={onRequestUpdatedRoute}
                recalculateRoute={recalculateRoute}
            />
        </Modal>
    );
};
