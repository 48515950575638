import { FC, useEffect, useState } from 'react';
import { FormikContextType, FormikValues, useField, useFormikContext } from 'formik';
import { MenuItem } from '@blueprintjs/core';
import { MultiSelect, ItemRenderer } from '@blueprintjs/select';
import { IFilterOption } from '../../../../interfaces/ucr';

import { FormGroup } from '../../../../components/v2/form/FormGroup';

interface IProps {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    options?: IFilterOption[];
    singleSelectOption?: IFilterOption;
    optionClassName?: string;
    itemListPredicate?: (query: string, items: IFilterOption[]) => IFilterOption[];
    popoverClassName?: string;
    withAllOption?: boolean;
}
interface IFieldProps extends IProps {
    field?: { [key: string]: any };
    meta?: { [key: string]: any };
    withAllOption?: boolean;
    optionsSelected: IFilterOption[];
    onItemSelect: (option: IFilterOption) => void;
    onRemove: (option: IFilterOption) => void;
    sortOptions?: boolean;
}

interface ITagChildrenProps {
    props: { option: IFilterOption };
}

const TagElement: FC<{ option: IFilterOption }> = ({ option }) => {
    return <span>{option.label}</span>;
};

export const ALL_OPTION = {
    label: 'All',
    value: '',
};

const onFilter = (query: string, item: IFilterOption, _index?: number, exactMatch?: boolean) => {
    const normalizedValue = item.value.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedValue === normalizedQuery;
    } else {
        return normalizedValue.includes(normalizedQuery);
    }
};

export const MultiSelectComponent: FC<IFieldProps> = ({
    className,
    label,
    required,

    optionsSelected,
    options,

    onItemSelect,
    onRemove,

    field = {},
    meta = {},
    withAllOption = true,
    sortOptions = true,
    itemListPredicate,
    popoverClassName,
    ...props
}) => {
    const renderOption: ItemRenderer<IFilterOption> = (option, { modifiers, handleClick }) => {
        const isOptionSelected = (option: IFilterOption) =>
            optionsSelected.some((opt) => opt.value === option.value);

        return (
            <MenuItem
                active={modifiers.active}
                icon={isOptionSelected(option) ? 'tick' : 'blank'}
                key={option.value}
                text={option.label}
                shouldDismissPopover={false}
                onClick={handleClick}
                className={props.optionClassName}
            />
        );
    };
    const renderTag = (option: IFilterOption) => <TagElement option={option} />;

    const orderedOptions = sortOptions
        ? options?.slice().sort((a, b) => a.label.localeCompare(b.label))
        : options;
    const allOptions = withAllOption ? [ALL_OPTION, ...(orderedOptions || [])] : orderedOptions;

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props?.name}
            required={required}
            touched={meta?.touched}
            error={meta?.error}
        >
            <MultiSelect
                itemRenderer={renderOption}
                tagRenderer={renderTag}
                tagInputProps={{
                    tagProps: {
                        onRemove: (e, tagProps) => {
                            e.stopPropagation();
                            onRemove((tagProps.children as ITagChildrenProps).props.option);
                        },
                    },
                }}
                items={allOptions || []}
                selectedItems={optionsSelected}
                onItemSelect={(option) => {
                    onItemSelect(option);
                }}
                noResults={<MenuItem disabled={true} text="No results." />}
                className="v2__form-multi-select-input"
                fill={true}
                popoverProps={{ minimal: true, popoverClassName: popoverClassName }}
                itemListPredicate={itemListPredicate}
                itemPredicate={onFilter}
                resetOnSelect
                {...props}
            />
        </FormGroup>
    );
};

const MultiSelectFormComponent: FC<IProps> = ({
    className,
    label,
    required,
    options,
    singleSelectOption,
    withAllOption = true,
    ...props
}) => {
    const [{ name, onBlur, onChange, value }, meta] = useField(props);
    const field = { name, onBlur, onChange, value };
    const [optionsSelected, setOptionsSelected] = useState<IFilterOption[]>([]);
    const [isPreselecting, setIsPreselecting] = useState(true);

    const { values, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();
    const addOption = (option: IFilterOption): void => {
        const newOptions = optionsSelected.find((opt) => opt.value === option.value)
            ? optionsSelected
            : [...optionsSelected, option];
        // if a single select option is selected it will clear other options apart
        // from select if any other value is selected it will clear it.
        if (singleSelectOption) {
            if (option.value === singleSelectOption.value) {
                setOptionsSelected([option]);
                setFieldValue(name, [singleSelectOption.value]);
            } else {
                const filteredOptions = newOptions.filter(
                    (opt) => opt.value !== singleSelectOption.value,
                );
                setOptionsSelected(filteredOptions);
                setFieldValue(
                    name,
                    filteredOptions.map((opt) => opt.value),
                );
            }
        } else {
            setOptionsSelected(newOptions);
            setFieldValue(
                name,
                newOptions.map((opt) => opt.value),
            );
        }
    };
    const removeOption = (option: IFilterOption): void => {
        const remainingOptions = optionsSelected.filter((opt) => opt.value !== option.value);
        setOptionsSelected(remainingOptions);
        setFieldValue(
            name,
            remainingOptions.map((opt) => opt.value),
        );
    };

    const selectOption = (option: IFilterOption): void => {
        if (optionsSelected.some((opt) => opt.value === option.value)) {
            removeOption(option);
        } else {
            addOption(option);
        }
    };

    useEffect(() => {
        if (!!values[name] && optionsSelected.length === 0 && isPreselecting) {
            const preSelectedOptions =
                options?.filter((item: IFilterOption) => values[name].includes(item?.value)) || [];

            setOptionsSelected(preSelectedOptions);
            setIsPreselecting(false);
        }
    }, [name, options, values, optionsSelected, isPreselecting]);

    return (
        <MultiSelectComponent
            className={className}
            label={label}
            required={required}
            field={field}
            meta={meta}
            optionsSelected={optionsSelected}
            options={options}
            onItemSelect={selectOption}
            onRemove={removeOption}
            withAllOption={withAllOption}
            {...props}
        />
    );
};

export default MultiSelectFormComponent;
