import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import {
    addUser,
    getHcpFunctions,
    getUsers,
    resetPassword,
    setHcpFunction,
    updateUser,
    updateUserAbsence,
    updateUserFromController,
} from '../api/usersApi';

class UsersStore {
    constructor(rootStore) {
        makeObservable(this, {
            users: observable,
            loaded: observable,
            filters: observable,
            selected: observable,
            loading: observable,
            allUsers: computed,
            selectedUsers: computed,
            hcpUsers: computed,
            getUsers: action,
            setUserStatus: action,
            startTimer: action,
            dispose: action,
            addUser: action,
            setFilters: action,
            focusedHcp: observable,
            hcpFunctions: observable,
        });

        this.rootStore = rootStore;
    }

    users = [];
    loaded = false;
    loading = false;
    filters = {};
    selected = [];
    focusedHcp = null;
    hcpFunctions = [];
    loadingFunctions = false;

    select = (userId) => {
        if (!this.selected.includes(userId)) {
            this.selected.push(userId);
        }
    };

    deselect = (userId) => {
        const index = this.selected.indexOf(userId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    get allUsers() {
        return this.users.map((user) => ({
            ...user,
            selected: this.selected.includes(user.userId),
        }));
    }

    get selectedUsers() {
        return this.allUsers.filter((user) => this.selected.includes(user.userId));
    }

    get hcpUsers() {
        return this.users.filter((user) => user.roles.includes('hcp'));
    }

    get filteredUsers() {
        let users = [...this.hcpUsers];
        Object.entries(this.filters).forEach(([name, values]) => {
            if (name === 'hcpType') {
                users = users.filter((user) =>
                    user.hcpTypes.some((hcpType) => values.includes(hcpType)),
                );
            }
        });
        return users;
    }

    setFilters = (filters) => {
        this.filters = filters;
    };

    getEnabledUsers(users) {
        return users.filter((user) => user.enabled);
    }

    setUsers = (users) => {
        this.users = users;
    };

    getUsers = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }

        const org = userSession.organisations[0];
        const response = await getUsers(userSession.tokens.id, org);

        if (response.status === 200) {
            const { users } = await response.json();
            this.users = users.map((user) => ({
                available: false,
                firstName: user.userName.split(' ').slice(0, -1).join(' '),
                lastName: user.userName.split(' ').slice(-1).join(' '),
                ...user,
            }));

            this.loaded = true;
            this.rootStore.lovsStore.setLov(
                'nominatedHcps',
                this.hcpUsers.map((user) => ({
                    label: `${user.userName} (${user.userId})`,
                    value: user.userId,
                    hcpTypes: user.hcpTypes,
                })),
            );
        }
    };

    setUserStatus = async (userId, enabled) => {
        try {
            const userData = { enabled };
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await updateUser(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    updateUserData = async (userId, userData) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await updateUserFromController(
                userId,
                userData,
                userSession.tokens.id,
            );

            if (response.status === 200) {
                let data = await response.json();

                const user = this.users.find((user: any) => user.userId === data.userId);
                user.staffPins = data.staffPins;
            }

            if (response.status !== 200) {
                throw new Error('Error updating user');
            }

            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    updateController = async (userId, userData) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await updateUser(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating user');
            }

            await this.getUsers();

            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    resetPassword = async (userId, legacy) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await resetPassword(userId, legacy, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error resetting password');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getUsers();
            this.fetchInterval = setInterval(() => {
                this.getUsers();
                this.getHcpFunctions(
                    moment(this.rootStore.ucrStore.selectedDate).format('YYYY-MM-DD'),
                );
            }, 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    addUser = async (userData) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await addUser(userData, userSession.tokens.id);
            const body = await response.json();
            await this.getUsers();
            return { response, body };
        } catch (err) {
            console.error(err);
            return { response: { status: 500 } };
        }
    };

    setUserDemographics = async (userId, demographicsData) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const userData = {
                band: demographicsData.band,
                enabled: demographicsData.enabled,
                gender: demographicsData.gender,
                phoneNumber: demographicsData.phoneNumber,
                email: demographicsData.email,
                organisation: {
                    name: this.rootStore.configStore.org,
                    data: demographicsData.orgData,
                },
                rolesData: demographicsData.rolesData,
                s1Username: demographicsData.s1Username,
            };

            const response = await updateUser(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    setUserAbsence = async (userId, absent) => {
        try {
            const userData = { absent };
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await updateUserAbsence(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating absence of user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    getHcpFunctions = async (date) => {
        this.loadingFunctions = true;
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await getHcpFunctions(date, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error getting hcp functions');
            }
            this.hcpFunctions = (await response.json()).functions;
            this.loadingFunctions = false;
        } catch (err) {
            console.error(err);
            this.loadingFunctions = false;
        }
    };

    setHcpFunction = async (userId, hcpDayResponsibility, date) => {
        try {
            const userData = { hcpDayResponsibility, date };
            this.focusedHcp = userId;

            this.loading = true;

            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await setHcpFunction(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating hcp function');
            }
            if (!this.loadingFunctions) {
                await this.getHcpFunctions(
                    moment(this.rootStore.ucrStore.selectedDate).format('YYYY-MM-DD'),
                );
            }
            this.loading = false;
            this.focusedHcp = null;

            return { successful: true };
        } catch (err) {
            console.error(err);
            this.loading = false;
            this.focusedHcp = null;

            return { successful: false };
        }
    };

    getUserHcpTypes = (userId) => {
        const user = this.users.find((user: any) => user.userId === userId);
        const { hcpType } = this.rootStore.lovsStore;
        return hcpType
            .filter((hcpType) => user.hcpTypes.includes(hcpType.value))
            .map((hcpType) => hcpType.label);
    };
    getUserBand = (userId) => {
        const user = this.users.find((user: any) => user.userId === userId);
        const { bands } = this.rootStore.lovsStore;
        if (bands && user && user.band) {
            const userBand = bands.find((band) => user.band === band.value);
            if (userBand) {
                return userBand.label;
            } else {
                return null;
            }
        }
        return null;
    };
}

export default UsersStore;
