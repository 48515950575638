import { useMutation, useQuery } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { Formik, FormikErrors } from 'formik';
import { observer } from 'mobx-react';
import { FC, useContext, useEffect, useState } from 'react';
import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import {
    arrivedDateTimeCanHaveAValue,
    finishedDateTimeCanHaveAValue,
    postVisitNotesCanHaveAValue,
} from '../../../../../models/patientVisitHelpers';
import { JobsContext } from '../../../../../providers';
import { Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import ChangeVisitStatusForm from './ChangeVisitStatusForm';
import {
    getDefaultArrivedDateTime,
    getDefaultFinishedTime,
    getDefaultMadeCurrentValue,
    getIsUser1,
    getPostVisitNotesError,
    getPropertyNames,
} from './changeVisitStatusHelpers';
import { IChangeStatusFormFormikValues } from './changeVisitStatusTypes';

/**
 * Iainb: the work I've done on ChangeVisitStatus and ChangeVisitStatusForm for VSU-1841 is suboptimal and could do
 * with a proper refactor,once we are on v2 api
 */
interface IProps {
    onClose: () => void;
}

type StatusUpdateType = {
    id: string;
    buddyJobStatus?: string;
    jobStatus?: JobStatus;
    startDateTime?: string | null;
    madeCurrentDateTime?: string | null;
    arrivedDateTime?: string | null;
    finishedDateTime?: string | null;
    buddyArrivedDateTime?: string | null;
    buddyFinishedDateTime?: string | null;
    buddyMadeCurrentDateTime?: string | null;
    lastUpdatedBy: string;
    version: number;
    postVisitNotes?: string | null;
    postVisitNotesBuddy?: string | null;
};

export interface IGetValuesToSendToApi {
    isHcp1: boolean;
    values: IChangeStatusFormFormikValues;
    currentJob: Patient | undefined;
    username: string;
    focusedJobId: string;
}

export function getValuesToSendToApi({
    isHcp1,
    values,
    currentJob,
    username,
    focusedJobId,
}: IGetValuesToSendToApi) {
    const propertyNames = getPropertyNames({ isHcp1 });
    // sometimes null / undefined matters sometimes it does not,  here it matters
    // bit of a gotcha, for input:StatusUpdateType if the property exists it must be
    // null or a string,  however undefined is acceptable for optional properties
    // even if explicitly set to undefined.
    // undefined will be ignored by sql, and the type checker does not catch
    // properties defined as undefined
    // not sure if we should do a check on input.propertyExists and is undefined set it to null
    let arrivedDateTime = null;
    let finishedDateTime = null;
    if (arrivedDateTimeCanHaveAValue({ jobStatus: values.newJobStatus })) {
        arrivedDateTime = values[propertyNames.arrivedDateTime]?.toISOString() || null;
    }
    if (finishedDateTimeCanHaveAValue({ jobStatus: values.newJobStatus })) {
        finishedDateTime = values[propertyNames.finishedDateTime]?.toISOString() || null;
    }
    const madeCurrentDateTime = values[propertyNames.madeCurrentDateTime]?.toISOString() || null;
    // the original code suggests that we only use the user1 jobStatus when user1 is false
    // then we do not use jobStatus if isUser1 is false, so I've gone with what that code suggests
    // that we only actually use values.newJobStatus.
    // given the null v undefined for gql undefined will not nullify the job status, type wise this is correct, not sure of our intention
    // I suspect that JobStatus should always have a value, so undefined is correct as that will mean no change, but
    // the intent is not clear
    const jobStatus = values.newJobStatus || undefined;
    let currentJobStatus;
    if (currentJob) {
        currentJobStatus = currentJob[propertyNames.jobStatus];
    }
    let input: StatusUpdateType = {
        id: focusedJobId,
        lastUpdatedBy: username,
        version: (currentJob && currentJob.version + 1) || 0,
    };
    /* todo - at some point its probably worth just using values and then mapping to the
    correct properties at this point to the input values
    eg input[propertyNames.arrivedDateTime] = values.arrivedDateTime
    rather than input[propertyNames.arrivedDateTime] = values[propertyNames.arrivedDateTime]
    in a round about way - and all the form fields similarly having their values mapped
    should be able to test fairly well with
    expect(updateJob).toBeCalledWith(...)
    and for ChangeVisitStatusForm
    whatever we send to ChangeVisitStatus.onSubmit
    */
    input[propertyNames.jobStatus] = jobStatus;
    input[propertyNames.arrivedDateTime] = arrivedDateTime;
    input[propertyNames.finishedDateTime] = finishedDateTime;
    input[propertyNames.madeCurrentDateTime] = madeCurrentDateTime;
    let postVisitNotesValue = null;
    if (postVisitNotesCanHaveAValue({ values, currentJobStatus })) {
        postVisitNotesValue = values.postVisitNotes || null;
    }
    input[propertyNames.postVisitNotes] = postVisitNotesValue;

    return input;
}

const ChangeVisitStatus: FC<IProps> = ({ onClose }) => {
    const {
        RootStore: {
            ucrStore: { focusedUser, focusedJobId, setOpenedDialog, jobs },
            userStore: {
                user: { username },
            },
            configStore: { isFeatureEnabled },
        },
    } = useStores() as { RootStore: any };

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                jobsContext.setRefreshAssignedJobs(true);
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [currentJob, setCurrentJob] = useState(
        jobs.find((job: Patient) => job.id === focusedJobId),
    );

    const { loading: jobLoading, data: jobData } = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
    });
    // SET CURRENT JOB
    useEffect(() => {
        if (!currentJob && jobData?.getJob) {
            setCurrentJob(jobData.getJob);
        }
    }, [currentJob, jobData]);

    const isDoubleUp = isMultiAssigneeJob(currentJob);

    const onSubmit = async (values: IChangeStatusFormFormikValues) => {
        const isHcp1 = getIsUser1({
            isDoubleUp,
            focusedUser,
        });
        const input = getValuesToSendToApi({
            isHcp1,
            values,
            currentJob,
            username,
            focusedJobId,
        });
        try {
            await updateJob({ variables: { input } });
            setOpenedDialog(Dialogs.NONE);

            AppToaster.show({
                // used in cypress test end-to-end tests
                // cypress/support/page_object/components/changeVisit.ts
                message: 'Job status changed successfully',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error changing job status', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to change the job status',
                intent: 'danger',
            });
        }
    };

    // hack fix in-part for VSU-2450, but also because it makes sense that you cant change a visit status if you have no visit to change
    // this will probably get refactored away when we get on to refactoring the code, and this
    // section gets made more sense of.
    if (!currentJob) {
        return null;
    }

    // Pre-populate the form with data values
    // Pre-population will trigger the validation
    const defaultMadeCurrent = getDefaultMadeCurrentValue({
        isHcp1: true,
        values: {},
        patientVisit: currentJob,
    });
    const defaultArrivedValue: Date | undefined = getDefaultArrivedDateTime({
        isHcp1: true,
        patientVisit: currentJob,
    });
    const finishedDateTime = getDefaultFinishedTime({
        arrivedDateTime: defaultArrivedValue,
        isHcp1: true,
        patientVisit: currentJob,
    });

    const buddyDefaultMadeCurrent = getDefaultMadeCurrentValue({
        isHcp1: false,
        values: {},
        patientVisit: currentJob,
    });
    const buddyDefaultArrivedValue: Date | undefined = getDefaultArrivedDateTime({
        isHcp1: false,
        patientVisit: currentJob,
    });
    const buddyDefaultfinishedDateTime = getDefaultFinishedTime({
        arrivedDateTime: buddyDefaultArrivedValue,
        isHcp1: false,
        patientVisit: currentJob,
    });

    return (
        <Formik
            initialValues={{
                newJobStatus: '',
                postVisitNotes: '',
                buddyMadeCurrentDateTime: buddyDefaultMadeCurrent,
                buddyArrivedDateTime: buddyDefaultArrivedValue,
                buddyFinishedDateTime: buddyDefaultfinishedDateTime,
                madeCurrentDateTime: defaultMadeCurrent,
                arrivedDateTime: defaultArrivedValue,
                finishedDateTime: finishedDateTime,
            }}
            onSubmit={onSubmit}
            validate={(values: IChangeStatusFormFormikValues) => {
                // We do some validation here and some in the form itself via getValidationWarnings
                const errors: FormikErrors<IChangeStatusFormFormikValues> = {};
                const postVisitNotesError = getPostVisitNotesError({
                    postVisitNotesMandatory: isFeatureEnabled('postVisitNotesMandatory'),
                    jobStatus: values.newJobStatus,
                    postVisitNotes: values.postVisitNotes,
                });
                if (postVisitNotesError) {
                    errors.postVisitNotes = postVisitNotesError;
                }
                // if  errors has properties then the form will not submit;
                // the submit fails silently.
                return errors;
            }}
        >
            {({ errors }) => (
                <ChangeVisitStatusForm
                    visit={currentJob}
                    loading={loading || jobLoading}
                    onClose={onClose}
                    errors={errors}
                />
            )}
        </Formik>
    );
};

export default observer(ChangeVisitStatus);
