import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { capitalize, formatDisplayDateTime, formatFullName } from '../../helpers/formatData';
import { Card, Tag } from '@blueprintjs/core';
import { upperCase } from 'lodash';
import classNames from 'classnames';

import InfoPair from '../../helpers/InfoPair';
import TimeRemaining from '../../helpers/TimeRemaining';
import HcpNameLink from '../detail/HcpNameLink';

class JobListItem extends Component {
    render() {
        const {
            data: {
                id,
                jobType,
                hcpNotifiedCount,
                systmOneRef,
                firstName,
                lastName,
                disposition,
                hcpId,
                expiryDateTime,
                blockSize,
                flags,
                nominatedHcps,
                startDateTime,
                endDateTime,
            },
            status,
            activeJob,
            onListItemClick,
        } = this.props;

        const user = this.props.RootStore.usersStore.users.find(
            (user: any) => user.userId === hcpId,
        );

        const hcpNominatedCount =
            Array.isArray(nominatedHcps) && nominatedHcps.length > 0 ? nominatedHcps.length : null;

        return (
            <Card
                className={classNames('jobs-list__item', `jobs-list__item--${status}`, {
                    'jobs-list__item--active': id === activeJob,
                })}
                interactive={true}
                onClick={(event) => onListItemClick(id, event)}
            >
                <div className="jobs-list__item-header">
                    <img src={`/images/icons/${jobType}.svg`} alt="" className="jobs-list__icon" />
                    {upperCase(jobType)}
                    <span className="jobs-list__item-header-info">
                        HCPs{' '}
                        {hcpNominatedCount && (
                            <span>
                                nominated: <strong>{hcpNominatedCount}</strong>,{' '}
                            </span>
                        )}
                        notified: <strong>{hcpNotifiedCount}</strong>
                    </span>
                </div>
                <Row className="listItemRow">
                    <Col xs={3}>
                        {systmOneRef && <InfoPair label="REF" content={systmOneRef} />}
                    </Col>
                    <Col xs={3}>
                        {disposition && (
                            <InfoPair label="DISPOSITION" content={capitalize(disposition)} />
                        )}
                        {blockSize && <InfoPair label="BLOCK SIZE" content={blockSize} />}
                    </Col>
                    <Col xs={3}>
                        {(firstName || lastName) && (
                            <InfoPair
                                label="PATIENT NAME"
                                content={formatFullName(firstName, lastName)}
                            />
                        )}
                    </Col>
                    <Col xs={3}>
                        {user && (
                            <InfoPair label="HCP">
                                <HcpNameLink
                                    hcpId={hcpId}
                                    hcpName={user.userName}
                                    {...this.props}
                                    closeModal
                                />
                            </InfoPair>
                        )}
                    </Col>
                </Row>
                <Row className="listItemRow">
                    <Col xs={3}>
                        <InfoPair label="EXPIRY" content={formatDisplayDateTime(expiryDateTime)} />
                    </Col>
                    <Col xs={3}>
                        <InfoPair label="REMAINING" lighter>
                            <TimeRemaining date={expiryDateTime} />
                        </InfoPair>
                    </Col>
                    <Col xs={3}>
                        {startDateTime && (
                            <InfoPair
                                label="START"
                                content={formatDisplayDateTime(startDateTime)}
                            />
                        )}
                    </Col>
                    <Col xs={3}>
                        {endDateTime && (
                            <InfoPair label="END" content={formatDisplayDateTime(endDateTime)} />
                        )}
                    </Col>
                </Row>
                {Array.isArray(flags) && flags.length > 0 && (
                    <Row className="listItemRow">
                        <Col xs={9}>
                            <InfoPair label="FLAGS" lighter>
                                {flags.map((flag) => (
                                    <div key={flag} className="flag-tag">
                                        <Tag minimal>{flag}</Tag>
                                    </div>
                                ))}
                            </InfoPair>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    }
}

export default JobListItem;
