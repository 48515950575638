import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';

export const useUsersTableColumnViewModel = () => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
            userStore: { isAdmin },
        },
    } = useStores() as { RootStore: RootStore };

    return {
        showUserStatusInfo: isAdmin,
        showMaxDriveTimeInfo: isFeatureEnabled('onDemandJobs'),
        showS1Info: isFeatureEnabled('s1Enabled'),
    };
};
