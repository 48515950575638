import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@blueprintjs/core';

class Modal extends Component {
    render() {
        const {
            match,
            lightbox,
            shadow,
            title,
            onClose,
            children,
            footer,
            showPrintButton,
            forwardedRef,
            testId,
        } = this.props;
        const tab = match && match.params.tab ? match.params.tab : 'available';

        return (
            <div
                data-testid={testId ?? ''}
                className={`modal ${shadow ? 'modal--shadow' : ''}`}
                ref={forwardedRef}
            >
                {lightbox && <div className="modal__lightbox"></div>}
                <div className="modal__wrapper">
                    <div className="modal__title">
                        <h2 className="h2 modal__heading">{title}</h2>
                        <div className="modal__header-btn">
                            {showPrintButton && (
                                <Button onClick={() => window.print()} minimal icon="print" />
                            )}
                            {onClose ? (
                                <Button onClick={onClose} minimal icon="cross" />
                            ) : (
                                <Link
                                    to={`/on-demand/${tab}/map`}
                                    className="bp5-button bp5-minimal"
                                >
                                    <Icon icon="cross" />
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="modal__content">{children}</div>
                    {footer && <div className="modal__footer">{footer}</div>}
                </div>
            </div>
        );
    }
}

export default Modal;
