import { AdminTypeV2, JobStatus, Pathway, Patient, PatientAlert } from '@doc-abode/data-models';
import { shouldBeSyncedToS1 } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import moment from 'moment';
import { VisitValuesType } from '../../components/pages/ucr/blocks/panels/VisitDetailsTypes';
import { IHcp } from '../../interfaces/ucr';
import filterPatientAlerts from './filterPatientAlerts';
import { getMomentEndTime } from './getEndDateTime';
import { GenderCompatibility, getGenderWarning, getLanguageWarning } from './helpers';

export const afterLatestTimeText = 'The planned time of visit is after to the latest time of visit';
export const beforeLatestTimeText =
    'The planned date or time of visit is prior to the earliest time of visit';

export const getCareStepWarnings = (
    values: FormikValues,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
): string[] | [] => {
    const stepWarnings = [];
    const today = moment();

    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
        seconds: 0,
        milliseconds: 0,
    });

    const isPastTime = values.startTime && visitDateTime.isBefore(today);
    const isDoubleUp = Number(values.staffRequired) === 2;

    // The pathway and admin should never be blank
    // but during testing of this capability the UI crashed due to undefined values
    const shouldSyncToS1 = shouldBeSyncedToS1(
        {
            referralPathway: values.referralPathway,
            disposition: values.disposition,
            activityType: values.activityType,
        } as Patient,
        pathways || [],
        adminTypes || [],
    );

    if (
        (isPastTime && !values.jobStatus) ||
        (isPastTime && values.jobStatus === JobStatus.PENDING) ||
        (isPastTime && values.jobStatus === JobStatus.ACCEPTED)
    ) {
        stepWarnings.push('Planned start time of the job is in the past!');
    }

    if (isDoubleUp && !values.buddyId?.length && values.hcpId?.length) {
        stepWarnings.push('Second staff member is not assigned!');
    }
    if (isDoubleUp && !values.hcpId?.length && values.buddyId?.length) {
        stepWarnings.push('First staff member is not assigned!');
    }

    if (shouldSyncToS1 && !values.systmOneRef) {
        stepWarnings.push(
            'This job will be synced to SystmOne but is not associated with a referral.',
        );
    }

    return stepWarnings;
};

export const checkLatestTimeWarning = (values: FormikValues): string[] | [] => {
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const latestHours = values?.availableTo ? moment(values.availableTo).hour() : '23';
    const latestMinutes = values?.availableTo ? moment(values.availableTo).minute() : '00';
    const latestTimeVsPlaned = visitDateTime
        ? moment(visitDateTime).set({
              hour: Number(latestHours),
              minute: Number(latestMinutes),
          })
        : null;

    const isPlannedAfterLatestTime =
        values.availableTo &&
        moment(getMomentEndTime(visitDateTime.toDate(), values.duration)).isAfter(
            latestTimeVsPlaned,
        );

    if (isPlannedAfterLatestTime) {
        timeFrameWarnings.push(afterLatestTimeText);
    }
    return timeFrameWarnings;
};

export const getTimeFrameWarnings = (values: FormikValues): string[] | [] => {
    if (!values.availableFrom) return [];
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const visitDate = moment(values.visitDate).format('MM-DD-YYYY');
    const earliestHours = values?.availableFrom ? moment(values.availableFrom).hour() : '00';
    const earliestMinutes = values?.availableFrom ? moment(values.availableFrom).minute() : '00';
    const earliestDate = moment(values.earliestDateOfVisit).format('MM-DD-YYYY');
    const earliestTime = values.earliestDateOfVisit
        ? moment(earliestDate, 'MM-DD-YYYY').set({
              hour: Number(earliestHours),
              minute: Number(earliestMinutes),
              seconds: 0,
              milliseconds: 0,
          })
        : null;

    const visitTimeWithoutSeconds = moment(visitDate, 'MM-DD-YYYY').set({
        hour: visitDateTime.hour(),
        minute: visitDateTime.minute(),
        seconds: 0,
        milliseconds: 0,
    });

    const isBeforeEarliestTime =
        !!values.earliestDateOfVisit && visitTimeWithoutSeconds.isBefore(earliestTime);

    if (isBeforeEarliestTime) {
        timeFrameWarnings.push(beforeLatestTimeText);
    }
    return timeFrameWarnings;
};

export const getAllWarnings = (
    values: FormikValues,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
): string[] => {
    const allWarnings = [];
    const isNotStarted =
        values.jobStatus === JobStatus.ACCEPTED ||
        values.jobStatus === JobStatus.PENDING ||
        !values.jobStatus;
    const isNotAborted =
        values.jobStatus !== JobStatus.HCP_ABORTED &&
        values.jobStatus !== JobStatus.CONTROLLER_ABORTED;

    if (isNotAborted && values.jobStatus !== JobStatus.COMPLETED) {
        allWarnings.push(...getTimeFrameWarnings(values));
    }
    if (isNotAborted) {
        allWarnings.push(...checkLatestTimeWarning(values));
    }

    if (isNotStarted || values.jobStatus === JobStatus.CURRENT) {
        allWarnings.push(...getCareStepWarnings(values, pathways, adminTypes));
    }

    return allWarnings;
};

export function isJobInQualifyingStateForBreachOfTimeWarnings(jobStatus: JobStatus): boolean {
    return [JobStatus.PENDING, JobStatus.AVAILABLE, JobStatus.ACCEPTED, JobStatus.CURRENT].includes(
        jobStatus,
    );
}

export function isBreachOfLatestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableTo) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the calculated end time is after the latest time of arrival, we have a breach
    // Not using the endDateTime as the field is not reliably set; hence calculating startDateTime + duration
    if (moment(getMomentEndTime(job.startDateTime, job.duration)).isAfter(job.availableTo)) {
        return true;
    }

    // If the planned end time has passed and the job is not in progress (= qualifying states), we have a breach
    if (moment().isAfter(job.availableTo)) {
        return true;
    }

    return false;
}

export function isBreachOfEarliestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableFrom) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the planned startTime is prior to the earliest time of arrival, we have a breach
    if (moment(job.startDateTime).isBefore(job.availableFrom)) {
        return true;
    }

    return false;
}

const getWarningVisitData = (values: Patient): VisitValuesType =>
    ({
        id: values.id,
        nhsNumber: values.nhsNumber,
        startTime: values.startDateTime,
        visitDate: values.dateOfVisit,
        earliestDateOfVisit: values.earliestDateOfVisit,
        availableFrom: values.availableFrom,
        availableTo: values.availableTo,
        duration: values.duration,
        arrivedDateTime: values.arrivedDateTime,
        finishedDateTime: values.finishedDateTime,
        jobStatus: values.jobStatus,
        hcpId: values.hcpId,
        buddyId: values.buddyId,
        staffRequired: values.staffRequired,
        disposition: values.disposition,
        referralPathway: values.referralPathway,
        activityType: values.activityType,
        systmOneRef: values.systmOneRef,
    } as any);

export function createStepWarnings(
    hcpUsers: any[],
    patient: Patient,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
    isSecondHCP?: boolean,
) {
    const hcpArr: (any | void)[] = [
        hcpUsers.find((hcp) =>
            isSecondHCP ? hcp.userId === patient?.buddyId : hcp.userId === patient?.hcpId,
        ),
    ];

    const genderWarnings = hcpArr.flatMap((hcp) =>
        getGenderWarning(hcp, patient.staffPreferredGender as Array<keyof GenderCompatibility>),
    );
    const hcpLanguageWarnings = hcpArr.flatMap((hcp) =>
        getLanguageWarning(hcp, patient.languagesSpoken),
    );
    return [
        ...getAllWarnings(getWarningVisitData(patient), pathways, adminTypes),
        ...genderWarnings,
        ...hcpLanguageWarnings,
    ].filter(Boolean); // Just make sure there's no empties
}

export function hasUnresolvedPatientAlerts(job: Patient, patientAlerts: PatientAlert[]) {
    return filterPatientAlerts(patientAlerts, job.id).hasUnresolvedAlerts;
}

export function hasGenderWarning(hcp?: IHcp, genderPreferences?: string[]) {
    return getGenderWarning(hcp, genderPreferences as Array<keyof GenderCompatibility>).length > 0;
}

export function hasLanguageWarning(hcp?: IHcp, languagesSpoken?: string[]) {
    return getLanguageWarning(hcp, languagesSpoken).length > 0;
}

export function hasGeolocationError(job: Patient): boolean {
    // If there are no address details, the job can't have address warnings
    // Using the postcode as it is one of the mandatory fields
    if (!job.postCode) {
        return false;
    }

    // If we have lat/long data, it's not an error
    if (job.latitude && job.longitude) {
        return false;
    }

    return true;
}

export function hasCarRequiredWarning(job: Patient): boolean {
    return job.carRequired || false;
}

export function hasComplexCare(job: Patient): boolean {
    return job.careComplexity === 'complex';
}

export function hasPlannedStartTimeInThePast(job: Patient): boolean {
    return job.startDateTime ? moment().utc().diff(job.startDateTime) >= 0 : false;
}

/**
 * function expects the caller to have checked whether S1 is enabled
 * @param job
 * @param adminTypes
 * @param pathways
 * @returns
 */
export function hasS1ReferralWarning(job: Patient, adminTypes: AdminTypeV2[], pathways: Pathway[]) {
    const shouldBeSynced = shouldBeSyncedToS1(job, pathways, adminTypes);

    if (!shouldBeSynced) {
        return false;
    }

    return !job.systmOneRef;
}
